import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-new-york',
    predictionPlace: {
      address: 'New York City, New York, United States',
      bounds: new LatLngBounds(new LatLng(40.917577, -73.700272), new LatLng(40.477399, -74.25909)),
    },
  },
  {
    id: 'default-chicago',
    predictionPlace: {
      address: 'Chicago, Illinois, United States',
      bounds: new LatLngBounds(new LatLng(42.023136999691, -87.5077920639626), new LatLng(41.6257400089756, -87.9310852234722)),
    },
  },
  {
    id: 'default-los-angeles',
    predictionPlace: {
      address: 'Los Angeles, California, United States',
      bounds: new LatLngBounds(new LatLng(34.1614409628202, -118.126644633049), new LatLng(33.9018943961945, -118.521476933898)),
    },
  },
  {
    id: 'default-boston',
    predictionPlace: {
      address: 'Boston, Massachusetts, United States',
      bounds: new LatLngBounds(new LatLng(42.3969789945055, -70.9862240485867), new LatLng(42.2279251715472, -71.1911559808676)),
    },
  },
  {
    id: 'default-houston',
    predictionPlace: {
      address: 'Houston, Texas, United States',
      bounds: new LatLngBounds(new LatLng(30.0403639807333, -95.0612081155106), new LatLng(29.5289219526407, -95.7279796190761)),
    },
  },
];
export default defaultLocations;
