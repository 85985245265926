import React from 'react';
import { FormattedDate } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { dateFromAPIToLocalNoon } from '../../util/dates';

import css from './BookingBreakdown.module.css';

const LineItemFirstMonthFees = props => {
  const { booking, listing, intl } = props;
  const publicData = listing && listing.attributes && listing.attributes.publicData || {};
  const timeUnit = publicData.timeUnit;
  const dateFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = dateFromAPIToLocalNoon(displayStart || start);
  const localEndDate = new Date( localStartDate.getTime());
  localEndDate.setMonth( localEndDate.getMonth() + 1 );
  localEndDate.setDate( 0 );

  return timeUnit === 'month' ? (
    <div className={css.lineItem}>
      <div className={css.subscriptionPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.itemLabel}>
            First Month Fees&nbsp;&nbsp;&nbsp;
          </div>
        </div>

        <div className={css.bookingPeriodSectionRigth}>
          <div className={css.itemLabel}>
            <FormattedDate value={localStartDate} {...dateFormatOptions} />
            &nbsp;-&nbsp;
            <FormattedDate value={localEndDate} {...dateFormatOptions} />
          </div>
        </div>
      </div>
    </div>
  ) : null ;
};
LineItemFirstMonthFees.defaultProps = { dateType: null };

LineItemFirstMonthFees.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemFirstMonthFees;
