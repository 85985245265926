import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldCheckbox, FieldCheckboxGroup } from '../../components';
import { required } from '../../util/validators';

import css from './EditListingPoliciesForm.module.css';

export const EditListingPoliciesFormComponent = props => {
  const setValue = ([fieldName], state, { changeValue }) => {
    changeValue(state, fieldName, () => ["yes"])
  };

  const resetValue = ([fieldName], state, { changeValue }) => {
    changeValue(state, fieldName, () => [])
  };

  return (
    <FinalForm
      {...props}
      mutators={{ resetValue, ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          form,
          values,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const checkInTimeLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.checkInTimeLabel',
        });
        const checkInTimePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.checkInTimePlaceholder',
        });
        const checkInTimeRequiredMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.checkInTimeRequired',
        });

        const checkOutTimeLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.checkOutTimeLabel',
        });
        const checkOutTimePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.checkOutTimePlaceholder',
        });
        const checkOutTimeRequiredMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.checkOutTimeRequired',
        });

        const tenantsAllowedLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.tenantsAllowedLabel',
        });
        const tenantsAllowedPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.tenantsAllowedPlaceholder',
        });
        const tenantsAllowedRequiredMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.tenantsAllowedRequired',
        });

        const childrenAllowedLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.childrenAllowedLabel',
        });
        const childrenAllowedPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.childrenAllowedPlaceholder',
        });
        const childrenAllowedRequiredMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.childrenAllowedRequired',
        });

        const infantsAllowedLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.infantsAllowedLabel',
        });
        const infantsAllowedPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.infantsAllowedPlaceholder',
        });
        const infantsAllowedRequiredMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.infantsAllowedRequired',
        });

        const catsAllowedLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.catsAllowedLabel',
        });
        const dogsAllowedLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.dogsAllowedLabel',
        });
        const noPetsAllowedLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.noPetsAllowedLabel',
        });

        const rulesOptions = [
          {
            key: 'rules_option_no_smoking',
            label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoSmoking'}),
          },
          {
            key: 'rules_option_no_parties',
            label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoParties'}),
          },
          {
            key: 'rules_option_no_events',
            label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoEvents'}),
          },
          {
            key: 'rules_option_no_loud_noise',
            label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoLoudNoise'}),
          },
          {
            key: 'rules_option_no_drugs',
            label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionNoDrugs'}),
          },
          {
            key: 'rules_option_self_check_in_out',
            label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionSelfCheckInOut'}),
          },
          {
            key: 'rules_option_hand_off_key',
            label: intl.formatMessage({ id: 'EditListingPoliciesForm.rulesOptionHandOffKey'}),
          }
        ];

        const rulesLabelMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.rulesLabel',
        });
        const rulesPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.rulesPlaceholder',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}

            <div className={css.checkInOutTimeBlock}>
              <FieldTextInput
                id="checkInTime"
                name="checkInTime"
                className={css.checkInTimeField}
                type="time"
                label={checkInTimeLabelMessage}
                placeholder={checkInTimePlaceholderMessage}
                validate={required(checkInTimeRequiredMessage)}
              />
              <FieldTextInput
                id="checkOutTime"
                name="checkOutTime"
                className={css.checkOutTimeField}
                type="time"
                label={checkOutTimeLabelMessage}
                placeholder={checkOutTimePlaceholderMessage}
                validate={required(checkOutTimeRequiredMessage)}
              />
            </div>

            <div className={css.tenantsBlock}>
              <FieldTextInput
                id="numberOfTenants"
                name="numberOfTenants"
                className={css.tenantsField}
                type="number"
                label={tenantsAllowedLabelMessage}
                placeholder={tenantsAllowedPlaceholderMessage}
                validate={required(tenantsAllowedRequiredMessage)}
                min={1}
                step={1}
              />
              <FieldTextInput
                id="numberOfChildren"
                name="numberOfChildren"
                className={css.childrenField}
                type="number"
                label={childrenAllowedLabelMessage}
                placeholder={childrenAllowedPlaceholderMessage}
                validate={required(childrenAllowedRequiredMessage)}
                min={0}
                step={1}
              />
              <FieldTextInput
                id="numberOfInfants"
                name="numberOfInfants"
                className={css.infantsField}
                type="number"
                label={infantsAllowedLabelMessage}
                placeholder={infantsAllowedPlaceholderMessage}
                validate={required(infantsAllowedRequiredMessage)}
                min={0}
                step={1}
              />
            </div>

            <div className={css.petsSubtitle}>
              <FormattedMessage id='EditListingPoliciesForm.petsSubtitle'/>
            </div>
            <div className={css.petsBlock}>
              <FieldCheckbox
                id="catsAllowed"
                name="catsAllowed"
                className={css.catsCheckbox}
                label={catsAllowedLabelMessage}
                value={'yes'}
                onChange={() => form.mutators.resetValue('noPetsAllowed')}
              />
              <FieldCheckbox
                id="dogsAllowed"
                name="dogsAllowed"
                className={css.dogsCheckbox}
                label={dogsAllowedLabelMessage}
                value={'yes'}
                onChange={() => form.mutators.resetValue('noPetsAllowed')}
              />
              <FieldCheckbox
                id="noPetsAllowed"
                name="noPetsAllowed"
                className={css.noPetsCheckbox}
                label={noPetsAllowedLabelMessage}
                value={'yes'}
                onChange={() => {
                  form.mutators.resetValue('catsAllowed');
                  form.mutators.resetValue('dogsAllowed');
                }}
              />
            </div>

            <div className={css.rulesSubtitle}>
              <FormattedMessage id='EditListingPoliciesForm.rulesSubtitle'/>
            </div>
            <div className={css.rulesBlock}>
              <FieldCheckboxGroup
                className={css.rulesOptions}
                id="rulesOptions"
                name="rulesOptions"
                options={rulesOptions}
                twoColumns={false}
              />
            </div>

            <FieldTextInput
              id="rules"
              name="rules"
              className={css.policy}
              type="textarea"
              label={rulesLabelMessage}
              placeholder={rulesPlaceholderMessage}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
