/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'type',
    label: 'Listing Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_type'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'apartment', label: 'Apartment' },
        { key: 'bed_n_breakfast', label: 'Bed & Breakfast' },
        { key: 'boutique_hotel', label: 'Boutique Hotel' },
        { key: 'condominium', label: 'Condominium' },
        { key: 'for_rent', label: 'For Rent' },
        { key: 'house', label: 'House' },
        { key: 'secondary_unit', label: 'Secondary Unit' },
        { key: 'townhouse', label: 'Townhouse' },
        { key: 'unique_space', label: 'Unique Space' },
        { key: 'vacation_rental', label: 'Vacation Rental' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'apartment', label: 'Apartment' },
        { key: 'bungalow', label: 'Bungalow' },
        { key: 'cabin', label: 'Cabin' },
        { key: 'casa_particular_cuba', label: 'Casa Particular Cuba' },
        { key: 'chalet', label: 'Chalet' },
        { key: 'cottage', label: 'Cottage' },
        { key: 'cycladic_house', label: 'Cycladic House' },
        { key: 'dammuso_italy', label: 'Dammuso (Italy)' },
        { key: 'dome_house', label: 'Dome house' },
        { key: 'earth_house', label: 'Earth house' },
        { key: 'farm_stay', label: 'Farm stay' },
        { key: 'garage', label: 'Garage' },
        { key: 'house', label: 'House' },
        { key: 'houseboat', label: 'Houseboat' },
        { key: 'land', label: 'Land' },
        { key: 'lighthouse', label: 'Lighthouse' },
        { key: 'office', label: 'Office' },
        { key: 'parking_space', label: 'Parking space' },
        { key: 'pension_south_korea', label: 'Pension (South Korea)' },
        { key: 'shepherds_hut_uk_france', label: "Shepherd&#039;s hut (UK.,France )" },
        { key: 'storage_locker', label: 'Storage locker'},
        { key: 'tiny_house', label: 'Tiny House' },
        { key: 'townhouse', label: 'Townhouse' },
        { key: 'trullo_italy', label: 'Trullo (Italy)' },
        { key: 'villa', label: 'Villa' },
      ],
    },
  },
  {
    id: 'tenantSpaceType',
    label: 'Tenant Space Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_tenantSpaceType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'entire-place', label: 'Entire Place' },
        { key: 'private-room', label: 'Private Room' },
        { key: 'shared-room', label: 'Shared Room' },
      ],
    },
  },
  {
    id: 'amenities',
    label: 'Amenities',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'washer_in_unit',
          label: 'Washer In Unit',
        },
        {
          key: 'pool',
          label: 'Pool',
        },
        {
          key: 'gym',
          label: 'Gym',
        },
        {
          key: 'elevator',
          label: 'Elevator',
        },
        {
          key: 'parking_spot',
          label: 'Parking Spot',
        },
        {
          key: 'fireplace',
          label: 'Fireplace',
        },
        {
          key: 'air_conditioning',
          label: 'Air Conditioning',
        },
        {
          key: 'doorman',
          label: 'Doorman',
        },
        {
          key: 'dishwasher',
          label: 'Dishwasher',
        },
        {
          key: 'Deck',
          label: 'Deck',
        },
        {
          key: 'storage',
          label: 'Storage',
        },
        {
          key: 'wheelchair',
          label: 'Wheelchair',
        },
        {
          key: 'hardwood_floors',
          label: 'Hardwood Floors',
        },
        {
          key: 'garden',
          label: 'Garden',
        },
        {
          key: 'balcony',
          label: 'Balcony',
        },
        {
          key: 'furnished',
          label: 'Furnished',
        },
        {
          key: 'view',
          label: 'View',
        },
        {
          key: 'student_friendly',
          label: 'Student Friendly',
        },
        {
          key: 'high_rise',
          label: 'High Rise',
        },
        {
          key: 'utilities_included',
          label: 'Utilities Included',
        },
        {
          key: 'wifi',
          label: 'Wifi',
        },
        {
          key: 'jacquzzi',
          label: 'Jacquzzi',
        },
        {
          key: 'tv',
          label: 'TV',
        },
        {
          key: 'cable',
          label: 'Cable',
        },
        {
          key: 'water',
          label: 'Water',
        },
        {
          key: 'electric',
          label: 'Electric',
        },
        {
          key: 'shampoo',
          label: 'Shampoo',
        },
        {
          key: 'heating',
          label: 'Heating',
        },
        {
          key: 'towels',
          label: 'Towels',
        },
        {
          key: 'bed_sheets',
          label: 'Bed sheets',
        },
        {
          key: 'soap',
          label: 'Soap',
        },
        {
          key: 'toilet_paper',
          label: 'Toilet Paper',
        },
        {
          key: 'fire_extinguisher',
          label: 'Fire extinguisher',
        },
        {
          key: 'carbon_monoxide_detector',
          label: 'Carbon monoxide detector',
        },
        {
          key: 'smoke_detector',
          label: 'Smoke detector',
        },
        {
          key: 'first_aid_kit',
          label: 'First Aid Kit',
        },
        {
          key: 'maid_service',
          label: 'Maid Service',
        },
        {
          key: 'landscaping',
          label: 'Landscaping',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
