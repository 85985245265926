import pick from 'lodash/pick';
import config from '../../config';
import {
  initiatePrivileged,
  transitionPrivileged,
  prepareStripeSubscription,
  fetchBidOffersLocal,
  sendNewOfferNotifications, get,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'void';
const TRANSITION_CONFIRM_PAYMENT = 'void';


// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';
export const SET_BID_OFFER = 'app/CheckoutPage/SET_BID_OFFER';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const PREPARE_SUBSCRIPTION_REQUEST = 'app/CheckoutPage/PREPARE_SUBSCRIPTION_REQUEST';
export const PREPARE_SUBSCRIPTION_SUCCESS = 'app/CheckoutPage/PREPARE_SUBSCRIPTION_SUCCESS';
export const PREPARE_SUBSCRIPTION_ERROR = 'app/CheckoutPage/PREPARE_SUBSCRIPTION_ERROR';

export const SHOW_LISTING_REQUEST = 'app/CheckoutPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/CheckoutPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/CheckoutPage/SHOW_LISTING_ERROR';

// ================ Reducer ================ //

const USE_SAVED_CARD = 'USE_SAVED_CARD';

const initialState = {
  listing: null,
  listingId: null,
  showListingInProgress: false,
  showListingError: null,
  bookingData: null,
  bookingDates: null,
  bidOffer: 0,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  prepareSubscriptionInProgress: false,
  prepareSubscriptionError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SET_BID_OFFER:
      return { ...state, bidOffer: payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case PREPARE_SUBSCRIPTION_REQUEST:
      return { ...state, prepareSubscriptionInProgress: true, prepareSubscriptionError: null };
    case PREPARE_SUBSCRIPTION_SUCCESS:
      return { ...state, prepareSubscriptionInProgress: false };
    case PREPARE_SUBSCRIPTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, prepareSubscriptionInProgress: false, prepareSubscriptionError: payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, listingId: payload.id, showListingInProgress: true, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, listing: payload.listing, showListingInProgress: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const setBidOffer = bidOffer => ({
  type: SET_BID_OFFER,
  payload: bidOffer,
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const prepareSubscriptionRequest = () => ({ type: PREPARE_SUBSCRIPTION_REQUEST });
export const prepareSubscriptionSuccess = () => ({ type: PREPARE_SUBSCRIPTION_SUCCESS });
export const prepareSubscriptionError = e => ({
  type: PREPARE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingSuccess = listing => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { listing },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const prepareSubscription = subscriptionParams => (dispatch, getState, sdk) => {
  const {
    selectedPaymentFlow,
    stripe,
    card,
    billingDetails,
    providerId
  } = subscriptionParams;
  const { currentUser } = getState().user;

  dispatch(prepareSubscriptionRequest());

  const createPM = selectedPaymentFlow === USE_SAVED_CARD ?
    Promise.resolve( null ) :
    stripe.createPaymentMethod({ type: 'card', card, billing_details: billingDetails, });

  return createPM
    .then( res => {
      if ( res && res.error ) {
        return Promise.reject( res );
      }

      const customerId = selectedPaymentFlow === USE_SAVED_CARD ?
        currentUser.stripeCustomer.attributes.stripeCustomerId : null;
      const paymentMethodId = selectedPaymentFlow === USE_SAVED_CARD ?
        currentUser.stripeCustomer.defaultPaymentMethod.attributes.stripePaymentMethodId : res.paymentMethod.id;

      return prepareStripeSubscription({ customerId, paymentMethodId, providerId, accountId: null });
    })
    .then( result => {
      const customerId = result.subscription.customerId;
      const paymentMethodId = result.subscription.paymentMethodId;
      const accountId = result.subscription.accountId;

      dispatch(prepareSubscriptionSuccess());

      return { customerId, paymentMethodId, accountId };
    })
    .catch(e => {
      dispatch(prepareSubscriptionError(storableError(e)));
      throw e;
    });
};

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    numberOfTenants: orderParams.numberOfTenants,
    numberOfChildren: orderParams.numberOfChildren,
    numberOfInfants: orderParams.numberOfInfants,
    bidOffer: orderParams.bidOffer,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  const { listing, bookingDates, bookingData } = getState().CheckoutPage;
  const { currentUser } = getState().user;
  const listingImageUrl = listing.images[0].attributes.variants['landscape-crop'].url;

  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: { protectedData: { subscription: orderParams.subscription, listingImageUrl }},
  };

  let order = null;

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      order = response.data.data;

      return fetchBidOffersLocal({
        listingId: listing.id.uuid,
        startDate: bookingDates.bookingStart,
        endDate: bookingDates.bookingEnd
      });
    })
    .then( response => {
      const { maxBidOffer, bidOffers } = response.data;
      const listingUnitType =
        listing.attributes.publicData.timeUnit === 'night' ? 'Nightly' : 'Monthly';

      const { bookingStart, bookingEnd } = bookingDates;
      const { numberOfTenants, numberOfChildren, numberOfInfants } = bookingData;

      return sendNewOfferNotifications({
        customerEMail: currentUser.attributes.email,
        listingId: listing.id.uuid,
        listingTitle: listing.attributes.title,
        startDate: bookingDates.bookingStart,
        endDate: bookingDates.bookingEnd,
        listingAmount: listing.attributes.price.amount,
        maxOfferAmount: maxBidOffer,
        numberOfOffers: bidOffers.length,
        listingUnitType,
        listingImageUrl,
        listingOffersUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/bo/${listing.id.uuid}?` +
          `bookingStart=${bookingStart.getTime()}&bookingEnd=${bookingEnd.getTime()}&` +
          `numberOfTenants=${numberOfTenants}&numberOfChildren=${numberOfChildren}&numberOfInfants=${numberOfInfants}`,
      });
    })
    .then(() => {
      dispatch(confirmPaymentSuccess(order.id));

      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    numberOfTenants: orderParams.numberOfTenants,
    numberOfChildren: orderParams.numberOfChildren,
    numberOfInfants: orderParams.numberOfInfants,
    bidOffer: orderParams.bidOffer,
  };

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const showListing = (listingId) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings.show(params)
    .then(data => {
      dispatch(addMarketplaceEntities(data));

      const entities = denormalisedResponseEntities(data);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the listing show response');
      }
      const listing = entities[0];
      dispatch(showListingSuccess(listing));

      return listing;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};
