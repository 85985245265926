import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_SECURITY_DEPOSIT, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidSecurityDeposit = depositLineItem => {
  return (
    depositLineItem &&
    depositLineItem.lineTotal instanceof Money &&
    depositLineItem.lineTotal.amount >= 0
  );
};

const LineItemSecurityDeposit = props => {
  const { transaction, intl } = props;

  const depositLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SECURITY_DEPOSIT
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let depositItem = null;

  if (depositLineItem) {
    if (!isValidSecurityDeposit(depositLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid security deposit fee line item:', depositLineItem);
      throw new Error('Security deposit should be present and the value should be zero or positive');
    }

    const securityDeposit = depositLineItem.lineTotal;
    const formattedSecurityDeposit = securityDeposit ? formatMoney(intl, securityDeposit) : null;

    depositItem = securityDeposit && securityDeposit.amount > 0 ? (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.securityDeposit" />
        </span>
        <span className={css.itemValue}>
          {formattedSecurityDeposit}
        </span>
      </div>
    ) : null;
  }

  return depositItem;
};

LineItemSecurityDeposit.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSecurityDeposit;
