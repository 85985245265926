import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_OCCUPANCY_TAX, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidOccupancyTax = occupancyLineItem => {
  return (
    occupancyLineItem &&
    occupancyLineItem.lineTotal instanceof Money &&
    occupancyLineItem.lineTotal.amount >= 0
  );
};

const LineItemOccupancyTax = props => {
  const { transaction, intl } = props;

  const occupancyLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_OCCUPANCY_TAX
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let occupancyItem = null;

  if (occupancyLineItem) {
    if (!isValidOccupancyTax(occupancyLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid occupancy tax line item:', occupancyLineItem);
      throw new Error('Occupancy tax should be present and the value should be zero or positive');
    }

    const quantity = occupancyLineItem.quantity.toString();
    const unitPrice = formatMoney( intl, occupancyLineItem.unitPrice );
    const occupancyTax = occupancyLineItem.lineTotal;
    const formattedOccupancyTax = occupancyTax ? formatMoney(intl, occupancyTax) : null;

    occupancyItem = occupancyTax && occupancyTax.amount > 0 ? (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.occupancyTax" />
        </span>
        <span className={css.itemValue}>
          <FormattedMessage
            id="BookingBreakdown.occupancyTaxPrice"
            values={{ unitPrice, quantity }}
          />
          &nbsp;=&nbsp;
          {formattedOccupancyTax}
        </span>
      </div>
    ) : null;
  }

  return occupancyItem;
};

LineItemOccupancyTax.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemOccupancyTax;
