import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './SectionStatistics.module.css';

import crmImage from './crm-image5.png';

const SectionStatistics = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.step}>
          <VisibilitySensor partialVisibility offset={{ top: 30 }}>
            {({ isVisible }) => (
              <h2 className={css.stepTitle}>
                $<span style={{ color: 'red'}}>
                { isVisible ? <CountUp end={3000} /> : null }
                </span>
              </h2>
            )}
          </VisibilitySensor>
          <p className={css.stepText}>
            LISTING PRICE
          </p>
        </div>

        <div className={css.step}>
          <VisibilitySensor partialVisibility offset={{ top: 30 }}>
            {({ isVisible }) => (
              <h2 className={css.stepTitle}>
                $<span style={{ color: 'red'}}>
                  { isVisible ? <CountUp end={2700} /> : null }
                </span>
              </h2>
            )}
          </VisibilitySensor>
          <p className={css.stepText}>
            HIGHEST OFFER
          </p>
        </div>

        <div className={css.step}>
          <VisibilitySensor partialVisibility offset={{ top: 30 }}>
            {({ isVisible }) => (
              <h2 className={css.stepTitle}>
                &nbsp;<span style={{ color: 'red'}}>
                  { isVisible ? <CountUp end={5} /> : null }
                </span>
              </h2>
            )}
          </VisibilitySensor>
          <p className={css.stepText}>
            OFFERS MADE
          </p>
        </div>
      </div>
      <div className={css.crmDemo}>
        <img src={crmImage} className={css.crmImage} />
      </div>

      <div className={css.createListingLink}>
        <NamedLink name="NewListingPage">
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionStatistics.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionStatistics.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionStatistics;
