import React, { useState } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  twitterPageURL,
} from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  Modal,
} from '../../components';
import {
  SendInquiryForm
} from '../../forms';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { sendInquiry } from '../../containers/LandingPage/LandingPage.duck';

import css from './Footer.module.css';
import { ensureListing, ensureOwnListing } from '../../util/data';

import solanaLogoImage from './solana-logo.png';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const FooterComponent = props => {
  const [ isSendInquiryModalOpen, setSendInquiryModalOpen ] = useState( false );

  const {
    rootClassName,
    className,
    intl,
    isAuthenticated,
    currentUser,
    sendInquiryInProgress,
    sendInquiryError,
    onSendInquiry,
    onManageDisableScrolling,
  } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const senderEMail = isAuthenticated ?
    currentUser && currentUser.attributes && currentUser.attributes.email : null;
  const senderFirstName = isAuthenticated ?
    currentUser && currentUser.attributes && currentUser.attributes.profile.firstName : null;
  const senderLastName = isAuthenticated ?
    currentUser && currentUser.attributes && currentUser.attributes.profile.lastName : null;

  const onSubmitInquiry = values => {
    const { senderEMail, senderFirstName, senderLastName, subject, message } = values;

    onSendInquiry( senderEMail, senderFirstName, senderLastName, subject, message )
      .then(() => {
        setSendInquiryModalOpen( false );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.poweredBySolanaNote}>
                <FormattedMessage id="Footer.poweredBySolanaNote"/>
              </div>
              <img className={css.poweredBySolanaImage} src={solanaLogoImage} />

              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="http://rentcotermsandconditions.com/" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <div className={css.link} onClick={() => setSendInquiryModalOpen( true )}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </div>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=New%20York%20City%2C%20New%20York%2C%20United%20States&bounds=40.917577%2C-73.700272%2C40.477399%2C-74.25909',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchNewYork" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Miami%2C%20Florida%2C%20United%20States&bounds=25.9794350332115%2C-80.1308910140325%2C25.5342490102697%2C-80.8736019999858',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchMiami" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Chicago%2C%20Illinois%2C%20United%20States&bounds=42.023136999691%2C-87.5077920639626%2C41.6257400089756%2C-87.9310852234722',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchChicago" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.1614409628202%2C-118.126644633049%2C33.9018943961945%2C-118.521476933898',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchLosAngeles" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Boston%2C%20Massachusetts%2C%20United%20States&bounds=42.3969789945055%2C-70.9862240485867%2C42.2279251715472%2C-71.1911559808676',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchBoston" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Washington%2C%20United%20States&bounds=49.0121490866648%2C-116.916070668425%2C45.5435400017256%2C-124.862197896945',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchWashington" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Houston%2C%20Texas%2C%20United%20States&bounds=30.0403639807333%2C-95.0612081155106%2C29.5289219526407%2C-95.7279796190761',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchHouston" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Philadelphia%2C%20Pennsylvania%2C%20United%20States&bounds=40.1379938330353%2C-74.9557610264649%2C39.8559420290523%2C-75.2826797961931',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchPhiladelphia" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Phoenix%2C%20Arizona%2C%20United%20States&bounds=33.9183919999994%2C-111.925317113704%2C33.2902580947003%2C-112.342782',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchPhoenix" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Atlanta%2C%20Georgia%2C%20United%20States&bounds=34.072832%2C-84.2183130148288%2C33.5853520383246%2C-84.6739429965336',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchAtlanta" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <ExternalLink href="http://rentcotermsandconditions.com/" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink href="http://rentcotermsandconditions.com/" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </ExternalLink>
                  </li>
                </ul>
                <div className={css.rentcoDaoLlc}>
                  <FormattedMessage id="Footer.rentcoDaoLlc" />
                </div>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <div className={css.tosAndPrivacyMobile}>
              <ExternalLink href="http://rentcotermsandconditions.com/" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </ExternalLink>
              <ExternalLink href="http://rentcotermsandconditions.com/" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </ExternalLink>
            </div>
            <div className={css.rentcoDaoLlc}>
              <FormattedMessage id="Footer.rentcoDaoLlc" />
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="Footer.sendInquiry"
        contentClassName={css.sendInquiryModalContent}
        isOpen={isSendInquiryModalOpen}
        onClose={() => setSendInquiryModalOpen( false )}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <SendInquiryForm
          className={css.sendInquiryForm}
          initialValues={{ senderEMail, senderFirstName, senderLastName }}
          submitButtonWrapperClassName={css.sendInquirySubmitButtonWrapper}
          listingTitle={"Contact Us"}
          sendInquiryError={sendInquiryError}
          onSubmit={onSubmitInquiry}
          inProgress={sendInquiryInProgress}
        />
      </Modal>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { sendInquiryInProgress, sendInquiryError } = state.LandingPage;


  return {
    isAuthenticated,
    currentUser,
    sendInquiryInProgress,
    sendInquiryError
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendInquiry: (senderEMail, senderFirstName, senderLastName, subject, message) =>
    dispatch(sendInquiry(senderEMail, senderFirstName, senderLastName, subject, message))
});

const Footer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FooterComponent);

export default Footer;
