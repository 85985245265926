import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInfo.module.css';

const IconCalendar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="#000000"
      viewBox="4 3 32 32"
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 4.8 9.6 c 0 -1.7625 1.4375 -3.2 3.2 -3.2 h 22.4 c 1.7688 0 3.2 1.4312 3.2 3.2 v 22.4 c 0 1.7688 -1.4312 3.2 -3.2 3.2 H 8 c -1.7688 0 -3.2 -1.4312 -3.2 -3.2 V 9.6 z M 8 12.8 v 19.2 h 22.4 V 12.8 H 8 z M 11.2 3.2 h 3.2 v 3.2 H 11.2 V 3.2 z M 24 3.2 H 27.2 v 3.2 h -3.2 V 3.2 z M 11.2 17.6 h 3.2 v 3.2 H 11.2 V 17.6 z M 11.2 24 h 3.2 V 27.2 H 11.2 V 24 z M 17.6 17.6 h 3.2 v 3.2 h -3.2 V 17.6 z M 17.6 24 h 3.2 V 27.2 h -3.2 V 24 z M 24 17.6 H 27.2 v 3.2 h -3.2 V 17.6 z M 24 24 H 27.2 V 27.2 h -3.2 V 24 z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconCalendar.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCalendar.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCalendar;
