import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEye.module.css';

const IconEye = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="28" height="28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.667 12.913c-1.457-2.384-3.417-4.299-5.879-5.746-2.462-1.447-5.061-2.17-7.799-2.17-2.738 0-5.337.723-7.799 2.17C3.729 8.614 1.769 10.529.312 12.913.104 13.277 0 13.636 0 13.99c0 .354.104 .713.312 1.077 1.457 2.384 3.417 4.299 5.879 5.746 2.462 1.447 5.061 2.17 7.799 2.17 2.738 0 5.337-.721 7.799-2.162 2.462-1.442 4.422-3.36 5.879-5.754.208-.364.312-.723.312-1.077C27.98 13.636 27.876 13.277 27.667 12.913zM10.64 8.642c.932-.932 2.048-1.397 3.349-1.397.208 0 .385.073 .531.219 .146.146 .219.323 .219.531 0 .208-.073.385-.218.531-.146.146-.323.219-.531.219-.895 0-1.66.318-2.295.952-.635.635-.952 1.4-.952 2.295 0 .208-.073.385-.219.531-.146.146-.323.219-.531.219-.208 0-.385-.073-.531-.219-.146-.146-.219-.323-.219-.531C9.243 10.69 9.709 9.574 10.64 8.642zM20.774 19.088c-2.087 1.264-4.348 1.897-6.784 1.897-2.436 0-4.697-.632-6.784-1.897s-3.823-2.964-5.207-5.098c1.582-2.457 3.565-4.294 5.949-5.512-.635 1.082-.952 2.254-.952 3.513 0 1.926.685 3.573 2.053 4.942s3.016 2.053 4.942 2.053c1.926 0 3.573-.685 4.942-2.053s2.053-3.016 2.053-4.942c0-1.259-.318-2.431-.953-3.513 2.384 1.218 4.367 3.055 5.949 5.512C24.597 16.124 22.861 17.823 20.774 19.088z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconEye.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEye.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEye;
