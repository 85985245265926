import axios from 'axios';
import qs from 'qs';

// ================ Action types ================ //

export const SEND_INQUIRY_REQUEST = 'app/LandingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/LandingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/LandingPage/SEND_INQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendInquiryInProgress: false,
  sendInquiryError: null,
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const sendInquiry = (senderEMail, senderFirstName, senderLastName, subject, message) =>
  (dispatch, getState, sdk) =>
{
  dispatch(sendInquiryRequest());

  const data = { senderEMail, senderFirstName, senderLastName, subject, message };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/api/send-inquiry`,
  };

  return axios(options)
    .then( result => {
      dispatch(sendInquirySuccess());
      console.log('Successfully sent inquiry to the marketplace admin');
      return result;
    })
    .catch( error => {
      dispatch(sendInquiryError(error.message));
      console.error('Sending inquiry to the admin failed with an error: ' + error.message);
      return error;
    });
};
