import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button, Form, FieldTextInput, FieldCurrencyInput, FieldRadioButton
} from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        values,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const { timeUnit = ''} = values;
      const isNightly = timeUnit === 'night';

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : 'EditListingPricingForm.pricePerMonth';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const timeUnitMonthlyLabel = intl.formatMessage({
        id: 'EditListingPricingForm.timeUnitMonthlyLabel',
      });
      const timeUnitNightlyLabel = intl.formatMessage({
        id: 'EditListingPricingForm.timeUnitNightlyLabel',
      });

      const securityDepositLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.securityDepositLabel',
      });
      const securityDepositPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.securityDepositPlaceholder',
      });

      const allowInstallmentsYesOption = intl.formatMessage({
        id: 'EditListingPricingForm.allowInstallmentsYes',
      });
      const allowInstallmentsNoOption = intl.formatMessage({
        id: 'EditListingPricingForm.allowInstallmentsNo',
      });

      const cleaningFeeLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.cleaningFeeLabel',
      });
      const cleaningFeePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.cleaningFeePlaceholder',
      });

      const occupancyTaxLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.occupancyTaxLabel',
      });
      const occupancyTaxPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.occupancyTaxPlaceholder',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const monthlyAttributesBlock = values.timeUnit === 'month' ? (
        <>
          <FieldCurrencyInput
            id="securityDeposit"
            name="securityDeposit"
            className={css.securityDepositField}
            label={securityDepositLabelMessage}
            placeholder={securityDepositPlaceholderMessage}
            currencyConfig={config.currencyConfig}
          />
          <div className={css.allowInstallmentsSubtitle}>
            <FormattedMessage id="EditListingPricingForm.allowInstallmentsSubtitle" />
          </div>
          <div className={css.allowInstallmentsBlock}>
            <FieldRadioButton
              id={'allowInstallments-yes'}
              name="allowInstallments"
              className={css.allowInstallmentsRadiobutton}
              label={allowInstallmentsYesOption}
              value="yes"
              showAsRequired={true}
            />
            <FieldRadioButton
              id={'allowInstallments-no'}
              name="allowInstallments"
              className={css.allowInstallmentsRadiobutton}
              label={allowInstallmentsNoOption}
              value="no"
              showAsRequired={true}
            />
          </div>
        </>
      ) : null;

      const nightlyAttributesBlock = values.timeUnit === 'night' ? (
        <>
          <div className={css.extraFeesSubtitle}>
            <FormattedMessage id="EditListingPricingForm.extraChargesSubtitle" />
          </div>
          <FieldCurrencyInput
            id="cleaningFee"
            name="cleaningFee"
            className={css.cleaningFeeField}
            label={cleaningFeeLabelMessage}
            placeholder={cleaningFeePlaceholderMessage}
            currencyConfig={config.currencyConfig}
          />
          <FieldTextInput
            id="occupancyTax"
            name="occupancyTax"
            className={css.occupancyTaxField}
            type="number"
            label={occupancyTaxLabelMessage}
            placeholder={occupancyTaxPlaceholderMessage}
            min={0}
            max={100}
            step={1}
          />
        </>
      ) : null;

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <div className={css.pricingSubtitle}>
            <FormattedMessage id="EditListingPricingForm.pricingSubtitle" />
          </div>
          <div className={css.timeUnitBlock}>
            <FieldRadioButton
              id={'timeUnit-month'}
              name="timeUnit"
              className={css.timeUnitRadiobutton}
              label={timeUnitMonthlyLabel}
              value="month"
              showAsRequired={true}
            />
            <FieldRadioButton
              id={'timeUnit-night'}
              name="timeUnit"
              className={css.timeUnitRadiobutton}
              label={timeUnitNightlyLabel}
              value="night"
              showAsRequired={true}
            />
          </div>
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          {monthlyAttributesBlock}
          {nightlyAttributesBlock}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
