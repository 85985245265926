import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CLEANING_FEE, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCleaningFee = cleaningLineItem => {
  return (
    cleaningLineItem &&
    cleaningLineItem.lineTotal instanceof Money &&
    cleaningLineItem.lineTotal.amount >= 0
  );
};

const LineItemCleaningFee = props => {
  const { transaction, intl } = props;

  const cleaningLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CLEANING_FEE
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let cleaningItem = null;

  if (cleaningLineItem) {
    if (!isValidCleaningFee(cleaningLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid cleaning fee line item:', cleaningLineItem);
      throw new Error('Cleaning fee should be present and the value should be zero or positive');
    }

    const quantity = cleaningLineItem.quantity.toString();
    const unitPrice = formatMoney( intl, cleaningLineItem.unitPrice );
    const cleaningFee = cleaningLineItem.lineTotal;
    const formattedCleaningFee = cleaningFee ? formatMoney(intl, cleaningFee) : null;

    cleaningItem = cleaningFee && cleaningFee.amount > 0 ? (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.cleaningFee" />
        </span>
        <span className={css.itemValue}>
          <FormattedMessage
            id="BookingBreakdown.cleaningFeePrice"
            values={{ unitPrice, quantity }}
          />
          &nbsp;=&nbsp;
          {formattedCleaningFee}
        </span>
      </div>
    ) : null;
  }

  return cleaningItem;
};

LineItemCleaningFee.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCleaningFee;
