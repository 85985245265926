import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from '../../components';

import css from './SectionVideoIntro.module.css';

import videoShot1Image from './images/video-shot1.png';

const INTRO_VIDEO_URL = 'https://rentco-ftw.collabgear.work/videos/final-new.mp4';

const noop = () => null;

const SectionVideoIntro = props => {
  const [isVideoPlaybackOpen, setVideoPlaybackOpen] = useState(false);

  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const handleVideoPlaybackClose = () => {
    setVideoPlaybackOpen(false);
  };

  return (
    <>
      <div className={classes}>
        <div className={css.imageWrapper} id='imageWrapperBlock'>
          <div
            className={css.videoPlaybackButton}
            id='videoPlaybackElement'
            onClick={() => {
              console.log('setVideoPlaybackOpen( true )');
              setVideoPlaybackOpen( true );
            }}
          >
            &#9658;
          </div>
          <img src={videoShot1Image } className={css.shotImage} />
        </div>
      </div>
      { isVideoPlaybackOpen ? (
        <Modal
          id="VideoPlaybackPopup"
          isOpen={isVideoPlaybackOpen}
          onClose={handleVideoPlaybackClose}
          usePortal
          onManageDisableScrolling={noop}
        >
          <div className={css.playbackContainer}>
            <video
              id="my-player"
              className={css.videoJs}
              controls
              preload="auto"
              poster={videoShot1Image}
            >
              <source src={INTRO_VIDEO_URL} type="video/mp4"></source>
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a
                web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">
                  supports HTML5 video
                </a>
              </p>
            </video>
          </div>
        </Modal>
      ) : null }
    </>
  );
};

SectionVideoIntro.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionVideoIntro.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionVideoIntro;
