import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHowAnimated.module.css';

import hostsCardImage1 from './images/hosts_card1.gif';
import hostsCardImage2 from './images/hosts_card2.gif';
import hostsCardImage3 from './images/hosts_card3.gif';
import hostsCardImage4 from './images/hosts_card4.gif';
import hostsCardImage5 from './images/hosts_card5.gif';
import hostsCardImage6 from './images/hosts_card6.gif';
import tenantsCardImage1 from './images/tenants_card1.gif';
import tenantsCardImage2 from './images/tenants_card2.gif';
import tenantsCardImage3 from './images/tenants_card3.gif';
import tenantsCardImage4 from './images/tenants_card4.gif';
import tenantsCardImage5 from './images/tenants_card5.gif';
import tenantsCardImage6 from './images/tenants_card6.gif';

const SectionHowAnimated = props => {
  const [ tenantProvider, setTenantProvider ] = useState('tenant');

  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  const actorTenantClass = (tenantProvider === 'tenant') ?
    css.tenantSelected : css.tenantUnselected;
  const actorProviderClass = (tenantProvider === 'provider') ?
    css.providerSelected : css.providerUnselected;


  const actorTypeSelector = (
    <div className={css.actorTypeWrapper}>
      <div className={css.actorTypeBlock}>
        <div className={actorTenantClass} onClick={() => setTenantProvider('tenant')}>
          <FormattedMessage id="SectionHowAnimated.tenantsButton" />
        </div>
        <div className={actorProviderClass} onClick={() => setTenantProvider('provider')}>
          <FormattedMessage id="SectionHowAnimated.providersButton" />
        </div>
      </div>
    </div>
  );

  const tenantCardsSection = (tenantProvider === 'tenant') ? (
    <>
      <div className={css.steps}>
        <div className={css.stepLeft}>
          <div className={css.cardImageWrapper}>
            <img src={tenantsCardImage1}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard1Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard1Text" />
            </div>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.cardImageWrapper}>
            <img src={tenantsCardImage2}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard2Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard2Text" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.steps}>
        <div className={css.stepLeft}>
          <div className={css.cardImageWrapper}>
            <img src={tenantsCardImage3}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard3Title" />
            </div>
            <div className={css.stepNote}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard3Note" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard3Text" />
            </div>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.cardImageWrapper}>
            <img src={tenantsCardImage4}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard4Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard4Text" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.steps}>
        <div className={css.stepLeft}>
          <div className={css.cardImageWrapper}>
            <img src={tenantsCardImage5}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard5Title" />
            </div>
            <div className={css.stepNote}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard5Note" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard5Text" />
            </div>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.cardImageWrapper}>
            <img src={tenantsCardImage6}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard6Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.tenantsCard6Text" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.actionBlock}>
        <NamedLink name="SearchPage" to={{ search: '', }} className={css.searchButton}>
          <FormattedMessage id="SectionHowAnimated.searchButton" />
        </NamedLink>
      </div>
    </>
  ) : null;

  const hostCardsSection = (tenantProvider === 'provider') ? (
    <>
      <div className={css.steps}>
        <div className={css.stepLeft}>
          <div className={css.cardImageWrapper}>
            <img src={hostsCardImage1}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.hostsCard1Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.hostsCard1Text" />
            </div>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.cardImageWrapper}>
            <img src={hostsCardImage2}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.hostsCard2Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.hostsCard2Text" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.steps}>
        <div className={css.stepLeft}>
          <div className={css.cardImageWrapper}>
            <img src={hostsCardImage3}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.hostsCard3Title" />
            </div>
            <div className={css.stepNote}>
              <FormattedMessage id="SectionHowAnimated.hostsCard3Note" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.hostsCard3Text" />
            </div>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.cardImageWrapper}>
            <img src={hostsCardImage4}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.hostsCard4Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.hostsCard4Text" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.steps}>
        <div className={css.stepLeft}>
          <div className={css.cardImageWrapper}>
            <img src={hostsCardImage5}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.hostsCard5Title" />
            </div>
            <div className={css.stepNote}>
              <FormattedMessage id="SectionHowAnimated.hostsCard5Note" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.hostsCard5Text" />
            </div>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.cardImageWrapper}>
            <img src={hostsCardImage6}/>
          </div>
          <div className={css.cardTextWrapper}>
            <div className={css.stepTitle}>
              <FormattedMessage id="SectionHowAnimated.hostsCard6Title" />
            </div>
            <div className={css.stepText}>
              <FormattedMessage id="SectionHowAnimated.hostsCard6Text" />
            </div>
          </div>
        </div>
      </div>
      <div className={css.actionBlock}>
        <NamedLink name="NewListingPage" className={css.listButton}>
          <FormattedMessage id="SectionHowAnimated.listButton" />
        </NamedLink>
      </div>
    </>
  ) : null;

  return (
    <div className={classes}>
      {actorTypeSelector}

      <div className={css.howToTitle}>
        <FormattedMessage id="SectionAnimated.howToTitle" />
      </div>
      {tenantCardsSection}
      {hostCardsSection}
    </div>
  );
};

SectionHowAnimated.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowAnimated.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowAnimated;
