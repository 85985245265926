import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { PublicKey } from '@solana/web3.js';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { Form, PrimaryButton, FieldTextInput, ExternalLink } from '../../components';

import css from './SolanaWalletAddressForm.module.css';

class SolanaWalletAddressFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { keyIsVerified: false, keyIsInvalid: false };
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            savePayoutError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            savePayoutInProgress,
            payoutDetailsSaved,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const publicKeyLabel = intl.formatMessage({
            id: 'SolanaWalletAddressForm.publicKeyLabel',
          });

          const publicKeyPlaceholder = intl.formatMessage({
            id: 'SolanaWalletAddressForm.publicKeyPlaceholder',
          });

          const publicKeyRequiredMessage = intl.formatMessage({
            id: 'SolanaWalletAddressForm.publicKeyRequired',
          });

          let genericError = null;

          if ( savePayoutError ) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="SolanaWalletAddressForm.genericFailure" />
              </span>
            );
          } else if ( this.state.keyIsVerified && this.state.keyIsInvalid ) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="SolanaWalletAddressForm.publicKeyInvalidFailure" />
              </span>
            );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || savePayoutInProgress ||
            ( this.state.keyIsVerified && this.state.keyIsInvalid );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.publicKeySection}>
                <FieldTextInput
                  type="text"
                  name="publicKey"
                  id={formId ? `${formId}.publicKey` : 'publicKey'}
                  label={publicKeyLabel}
                  placeholder={publicKeyPlaceholder}
                  validate={required(publicKeyRequiredMessage)}
                />
              </div>
              <OnChange name="publicKey">
                {(value, previous) => {
                  if( value ){
                    try {
                      let pubkey = new PublicKey( value );
                      let isSolana =  PublicKey.isOnCurve( pubkey.toString());
                      this.setState({ keyIsVerified: true, keyIsInvalid: !isSolana });
                    } catch (error) {
                      this.setState({ keyIsVerified: true, keyIsInvalid: true });
                    }
                  } else {
                    this.setState({ keyIsVerified: false, keyIsInvalid: false });
                  }
                }}
              </OnChange>

              <div className={css.footNote}>
                <FormattedMessage
                  id="SolanaWalletAddressForm.termsFootnote"
                  values={{
                    rentcoTermsLink: (
                      <ExternalLink href="https://www.rentcotermsandconditions.com/">
                        <FormattedMessage id="SolanaWalletAddressForm.termsFootnoteLink" />
                      </ExternalLink>
                    )
                  }}
                />
              </div>

              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton
                  type="submit"
                  inProgress={savePayoutInProgress}
                  ready={payoutDetailsSaved}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SolanaWalletAddressForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SolanaWalletAddressFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, func, string } = PropTypes;

SolanaWalletAddressFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,
};

const SolanaWalletAddressForm = compose(injectIntl)(SolanaWalletAddressFormComponent);

SolanaWalletAddressForm.displayName = 'SolanaWalletAddressForm';

export default SolanaWalletAddressForm;
