import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const CustomTypeSelectFieldMaybe = props => {
  const { name, id, types, intl } = props;
  const typeLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.typeLabel',
  });
  const typePlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.typePlaceholder',
  });
  const typeRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.typeRequired',
    })
  );
  return types ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={typeLabel}
      validate={typeRequired}
    >
      <option disabled value="">
        {typePlaceholder}
      </option>
      {types.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomTypeSelectFieldMaybe;
