import { storableError } from '../../util/errors';
import { fetchBidOffersLocal } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import { setInitialValues, showListing } from '../CheckoutPage/CheckoutPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_BID_OFFERS_REQUEST = 'app/BidOffersPage/FETCH_BID_OFFERS_REQUEST';
export const FETCH_BID_OFFERS_SUCCESS = 'app/BidOffersPage/FETCH_BID_OFFERS_SUCCESS';
export const FETCH_BID_OFFERS_ERROR = 'app/BidOffersPage/FETCH_BID_OFFERS_ERROR';

export const SET_BOOKING_DATES_REQUEST = 'app/BidOffersPage/SET_BOOKING_DATES_REQUEST';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  startDate: null,
  endDate: null,
  bidOffers: [],
  maxBidOffer: 0,
  fetchBidOffersInProgress: false,
  fetchBidOffersError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BID_OFFERS_REQUEST:
      return {
        ...state,
        listing: null,
        bidOffers: [],
        maxBidOffer: 0,
        fetchBidOffersInProgress: true,
        fetchBidOffersError: null,
      };
    case FETCH_BID_OFFERS_SUCCESS: {
      const { listing, bidOffers, maxBidOffer } = payload;

      return { ...state, listing, bidOffers, maxBidOffer, fetchBidOffersInProgress: false };
    }
    case FETCH_BID_OFFERS_ERROR: {
      return { ...state, fetchBidOffersInProgress: false, fetchBidOffersError: null };
    }

    case SET_BOOKING_DATES_REQUEST: {
      const { startDate, endDate } = payload;

      return { ...state, startDate, endDate };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const fetchBidOffersRequest = () => ({ type: FETCH_BID_OFFERS_REQUEST, payload: {}});
export const fetchBidOffersSuccess = results => ({ type: FETCH_BID_OFFERS_SUCCESS, payload: results });
export const fetchBidOffersError = error => ({
  type: FETCH_BID_OFFERS_ERROR,
  payload: error,
  error: true,
});

export const setBookingDatesRequest = ( startDate, endDate ) => ({
  type: SET_BOOKING_DATES_REQUEST,
  payload: { startDate, endDate }
});

// ================ Thunk ================ //

export const fetchBidOffers = ( listingId, startDate, endDate ) => {
  return (dispatch, getState, sdk) => {
    dispatch(fetchBidOffersRequest());

    const fetchOffers = typeof window === 'object' ?
      fetchBidOffersLocal({ listingId, startDate, endDate }) :
        Promise.resolve({ data: { listing: null, maxBidOffer: 0, transactions: []}});

    let listing, maxBidOffer, bidOffers;

    return fetchOffers
      .then( result => {
        maxBidOffer = result.data.maxBidOffer;
        bidOffers =  result.data.bidOffers;

        return sdk.listings.show({id: new UUID( listingId )});
      })
      .then(result => {
        listing = result.data.data;

        return dispatch(fetchBidOffersSuccess({ listing, maxBidOffer, bidOffers }));
      })
      .catch(e => {
        console.error('Error fetching the bid offers');
        console.error( e );
        dispatch(fetchBidOffersError(storableError(e)));
      });
  };
};

export const loadData = params => (dispatch, getState, sdk) => {
  const state = getState().SolanaCheckoutPage;

  let initState = null;
  if( location && location.search ){
    const rawParams = location.search.replace('?', '').split('&');
    const params = {};
    rawParams.forEach( param => {
      const paramParts = param.split('=');
      params[ paramParts[ 0 ]] = paramParts[ 1 ];
    });

    const bookingDates = {
      bookingStart: new Date( Number.parseInt( params.bookingStart )),
      bookingEnd: new Date( Number.parseInt( params.bookingEnd )),
    };
    const bookingData = {
      numberOfTenants: Number.parseInt( params.numberOfTenants ),
      numberOfChildren: Number.parseInt( params.numberOfChildren ),
      numberOfInfants: Number.parseInt( params.numberOfInfants ),
    };

    initState = { bookingDates, bookingData };
  }

  if( initState ) {
    dispatch( setInitialValues( initState ));
    dispatch(showListing(params.id));
  }

  const startDate = initState
    ? initState.bookingDates.bookingStart
    : state.bookingDates && state.bookingDates.bookingStart
      ? state.bookingDates.bookingStart
      : null;
  const endDate = initState
    ? initState.bookingDates.bookingEnd
    : state.bookingDates && state.bookingDates.bookingEnd
      ? state.bookingDates.bookingEnd
      : null;

  return dispatch( fetchBidOffers( params.id, startDate, endDate ));
};
