import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

const DAYS_PER_MONTH = 30;

const LineItemNegotiation = props => {
  const { transaction, listing, unitType, intl } = props;
  const publicData = listing && listing.attributes && listing.attributes.publicData || {};
  const timeUnit = publicData.timeUnit;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity : null;
  const listingPrice = listing ? listing.attributes.price : null;
  const negotiationPrice = quantity && listingPrice && unitPurchase ?
    formatMoney(intl, new Money( unitPurchase.lineTotal.amount - quantity * listingPrice.amount, 'USD'))
      : null;

  return negotiationPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.negotiation" />
      </span>
      <span className={css.itemValue}>
        {negotiationPrice}
      </span>
    </div>
  ) : null;
};

LineItemNegotiation.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemNegotiation;
