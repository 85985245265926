import React, { Component, useState, useEffect, useMemo, useRef } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import BigNumber from "bignumber.js";
import {
  createQR, encodeURL, TransferRequestURLFields, findReference, validateTransfer,
  FindReferenceError, ValidateTransferError, TransactionRequestURLFields
} from "@solana/pay";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl, Connection, Keypair, PublicKey, Transaction } from "@solana/web3.js";
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react'
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsFunded,
  txIsRequested,
  txIsPreauthorized,
  txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  AvatarSmall,
  BookingPanel,
  Modal,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  PrimaryButton,
} from '../../components';
import { SendMessageForm, CancellationReasonForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_PREAUTHORISED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_FUNDED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const SolanaCheckoutPane = props => {
  const [ solanaTransaction, setSolanaTransaction ] = useState( null );
  const [ transactionInProgress, setTransactionInProgress ] = useState( false );
  const [ message, setMessage ] = useState( null );
  const [ solanaError, setSolanaError ] = useState( null );

  const buyerAccount = useWallet();

  const { transaction, fundInProgress, fundError, onFundBooking } = props;
  const txId = transaction ? transaction.id.uuid : null;
  const txProvider = transaction.provider;
  const providerWalletAddress = txProvider.attributes.profile.publicData.solanaWalletAddress;

  const payinTotal = transaction ? transaction.attributes.payinTotal : { amount: 0 };
  const payoutTotal = transaction ? transaction.attributes.payoutTotal : { amount: 0 };

  // Rentco Rentals marketplace wallet public key
  const shopAddress = new PublicKey( process.env.REACT_APP_MARKETPLACE_WALLET_ADDRESS );

  // this is the same for everyone!
  const usdcAddress = new PublicKey( process.env.REACT_APP_MARKETPLACE_USDC_ADDRESS );

  // listing provider wallet public key
  const providerAddress = new PublicKey( providerWalletAddress );

  // ref to a div where we'll show the QR code
  const qrRef = useRef( null );

  // Unique address that we can listen for payments to
  const reference = useMemo(() => Keypair.generate().publicKey, []);
  const commissionReference = useMemo(() => Keypair.generate().publicKey, []);

  // Read the URL query (which includes our chosen products)
  const searchParams = {
    reference: reference.toString(),
    commissionReference: commissionReference.toString(),
    txId,
    sellerPublicKey: providerAddress.toString(),
  };
  const encodedSearchParams =
    Object.entries( searchParams ).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

  // Get a connection to Solana devnet
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = clusterApiUrl(network);
  const connection = new Connection(endpoint);

  // Use our API to fetch the transaction for the selected items
  const getTransaction = async () => {
    if (!buyerAccount) {
      return;
    }

    const body = {
      account: buyerAccount.publicKey.toString(),
    };

    const response = await fetch(`/api/solana-transaction?${ encodedSearchParams }`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });

    const json = await response.json();

    if (response.status !== 200) {
      console.error(json);
      return;
    }

    // Deserialize the transaction from the response
    const solTransaction = Transaction.from(Buffer.from(json.transaction, 'base64'));
    setSolanaTransaction( solTransaction );
    setMessage( json.message );

    return solTransaction;
  };

  // Send the fetched transaction to the connected wallet
  async function trySendTransaction() {
    setTransactionInProgress( true );

    const solTransaction = await getTransaction();

    if (!solTransaction) {
      setTransactionInProgress( false );
      return;
    }

    try {
      await buyerAccount.sendTransaction( solTransaction, connection );
    } catch (e) {
      setSolanaError( e );
      console.error(e);
    }
  }

  // Show the QR code
  useEffect(() => {
    // window.location is only available in the browser, so create the URL in here
    const { location } = window;

    const apiUrl =
      `${process.env.REACT_APP_CANONICAL_ROOT_URL}/api/solana-transaction?${ encodedSearchParams }`;

    const urlParams = {
      link: new URL( apiUrl ),
      label: "Cookies Inc",
      message: "Thanks for your order! 🍪",
    };
    const solanaUrl = encodeURL( urlParams );
    const qr = createQR(solanaUrl, 512, 'transparent');

    if ( qrRef.current ) {
      qrRef.current.innerHTML = '';
      qr.append( qrRef.current );
    }
  });

  // Check every 0.5s if the transaction is completed
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        // Check if there is any transaction for the reference
        const signatureInfo = await findReference(connection, reference, { finality: 'confirmed' });

        // Validate that the transaction has the expected recipient, amount and SPL token
        // TBD: find out the transfer validation issue
        /*await validateTransfer(
          connection,
          signatureInfo.signature,
          {
            recipient: providerAddress,
            amount: new BigNumber(( payoutTotal.amount / 100 ).toString()),
            splToken: usdcAddress,
            reference,
          },
          { commitment: 'confirmed' }
        );*/

        // Check if there is any transaction for the commission reference
        const commissionSignatureInfo =
          await findReference(connection, commissionReference, { finality: 'confirmed' });
        // Validate the marketplace commission transfer
        await validateTransfer(
          connection,
          commissionSignatureInfo.signature,
          {
            recipient: shopAddress,
            amount: new BigNumber((( payinTotal.amount - payoutTotal.amount ) / 100 ).toString()),
            splToken: usdcAddress,
            reference: commissionReference,
          },
          { commitment: 'confirmed' }
        );

        setSolanaError( null );

        if( !fundInProgress ){
          onFundBooking( transaction.id, signatureInfo.signature ).then(() => {
            setTransactionInProgress( false );
          });
        }
      } catch (e) {
        if (e instanceof FindReferenceError) {
          // No transaction found yet, ignore this error
          return;
        }

        setTransactionInProgress( false );
        setSolanaError( e );

        if (e instanceof ValidateTransferError) {
          // Transaction is invalid
          console.error('Transaction is invalid', e);
          return;
        }
        console.error('Unknown error', e)
      }
    }, 500);

    return () => {
      clearInterval(interval)
    }
  }, []);

  return (
    <div className={css.solanaPaymentBlock}>
      <h2 className={css.solanaPaymentTitle}>
        <FormattedMessage id="TransactionPanel.solanaPaymentTitle" />
      </h2>
      <WalletMultiButton />
      { buyerAccount && buyerAccount.connected && transaction ? (
        <PrimaryButton
          className={css.fundBookingButton}
          inProgress={transactionInProgress}
          disabled={transactionInProgress}
          onClick={() => trySendTransaction()}
        >
          <FormattedMessage id="TransactionPanel.fundBooking" />
        </PrimaryButton>
      ) : (
        <div className={css.noPaypentButtonMessage}>
          <FormattedMessage id="TransactionPanel.solanaWalletConnectionRequired" />
        </div>
      )}
      { solanaError ? (
        <div className={css.solanaPaymentError}>
          <FormattedMessage
            id="TransactionPanel.solanaPaymentError"
            values={{ errorMessage: solanaError.message, br: <br/>}}
          />
        </div>
      ) : null }
      { fundError ? (
        <div className={css.solanaPaymentError}>
          <FormattedMessage
            id="TransactionPanel.flexFundingError"
            values={{ errorMessage: fundError.message, br: <br/>}}
          />
        </div>
      ) : null }
      { transaction ? (
        <div className="flex flex-col items-center gap-8">
          <div ref={qrRef} />
        </div>
      ) : null }
    </div>
  )
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      showOtherOffers: false,
      isCancellationReasonModalOpen: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.onSubmitSaleCancellation = this.onSubmitSaleCancellation.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  onSubmitSaleCancellation( values ){
    const { transaction, transactionRole, onCancelSaleByCustomer,
            onLockCancellation, onCancelSaleByProvider, onLockCancellationByProvider } = this.props;

    let cancelPromise = null;
    if( transactionRole === 'provider' ){
      cancelPromise = values.reason === 'will-handle-transaction-with-customer-provider-on-my-own' ?
        onLockCancellationByProvider( transaction.id ) : onCancelSaleByProvider( transaction.id );
    } else {
      cancelPromise = values.reason === 'will-handle-transaction-with-customer-provider-on-my-own' ?
        onLockCancellation( transaction.id ) : onCancelSaleByCustomer( transaction.id );
    }

    cancelPromise
      .then(() => {
        this.setState({ isCancellationReasonModalOpen: false });
      })
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      bidOffers,
      maxBidOffer,
      fetchBidOffersInProgress,
      fetchBidOffersError,
      cancelSaleInProgress,
      cancelSaleError,
      onCancelSaleByCustomer,
      onCancelSaleWithoutRefund,
      endpoint,
      publicKey,
      wallets,
      fundInProgress,
      fundError,
      onFundBooking,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const listingPrice =
      currentListing && currentListing.attributes && currentListing.attributes.price
        || { amount: 0 };
    const listingPriceAmount = listingPrice.amount / 100;
    const listingPublicData =
      currentListing && currentListing.attributes && currentListing.attributes.publicData || {};
    const { timeUnit = ''} = listingPublicData;
    const maxBidOfferValue = maxBidOffer / 100;

    const { protectedData = {}} = currentTransaction.attributes;
    const { subscription = {}} = protectedData;
    const { scheduleId } = subscription;

    let isCancellationAllowed = true;
    if( isCancellationAllowed ){
      const { createdAt } = currentTransaction.attributes || {};
      const { booking = {}} = currentTransaction;
      const { start } = booking.attributes || {};

      if( !createdAt || !start ) {
        isCancellationAllowed = false;
      } else {
        const currentDate = new Date();

        if( currentDate.getTime() - createdAt.getTime() > 48 * 60 * 60 * 1000 ||
            start.getTime() - currentDate.getTime() < 14 * 24 * 60 * 60 * 1000 )
        {
          isCancellationAllowed = false;
        }
      }
    }

    const stateDataFn = tx => {
      if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPreauthorized(tx)) {
        return {
          headingState: HEADING_PREAUTHORISED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showCancelButton: !isCustomerBanned && !isProviderBanned && isCancellationAllowed,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsFunded(tx)) {
        return {
          headingState: HEADING_FUNDED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    let unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';
    if( timeUnit === 'month' )
      unitTranslationKey = 'TransactionPanel.perMonth';

    const timeUnitKey = timeUnit === 'month' ?
      "BidOffersPage.unitMonthly" :
      "BidOffersPage.unitNightly";

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const bookingStart = currentTransaction.booking.attributes.start;
    const bookingEnd = currentTransaction.booking.attributes.end;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        showCancelButton={stateData.showCancelButton}
        cancellationLocked={currentTransaction.attributes.protectedData.cancellationLocked}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        cancelInProgress={cancelSaleInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        cancelSaleError={cancelSaleError}
        onAcceptSale={() =>
          onAcceptSale( currentTransaction.id, bookingStart, bookingEnd,
            currentTransaction.attributes.protectedData.subscription )
        }
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
        onCancelSale={() => this.setState({ isCancellationReasonModalOpen: true })}
      />
    );

    const currentUserIsCustomer =
      currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
    const solanaPaymentClause = currentUserIsCustomer &&
      txIsAccepted( currentTransaction ) && typeof window !== 'undefined'? (
        <SolanaCheckoutPane
          transaction={currentTransaction}
          fundInProgress={fundInProgress}
          fundError={fundError}
          onFundBooking={onFundBooking}
        />
      ) : null;

    const showSendMessageForm = !isCustomerBanned && !isCustomerDeleted &&
      !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const bidStatsRow = txIsRequested( transaction ) ? (
      <div className={css.bidStatsRow}>
        <div className={css.bidStatsCell}>
          <div className={css.bidStatsValue}>
            $ <span className={css.bidStatsFigure}>{listingPriceAmount}</span>
          </div>
          <div className={css.bidStatsExplanation}>
            <FormattedMessage id="BidOffersPage.listingPrice" />
          </div>
          <div className={css.bidStatsUnit}>
            <FormattedMessage id={timeUnitKey} />
          </div>
        </div>
        <div className={css.bidStatsCell}>
          <div className={css.bidStatsValue}>
            $ <span className={css.bidStatsFigure}>{maxBidOfferValue}</span>
          </div>
          <div className={css.bidStatsExplanation}>
            <FormattedMessage id="BidOffersPage.maxBidOffer" />
          </div>
          <div className={css.bidStatsUnit}>
            <FormattedMessage id={timeUnitKey} />
          </div>
        </div>
        <div className={css.bidStatsCell}>
          <div className={css.bidStatsValue}>
            <span className={css.bidStatsFigure}>{bidOffers.length}</span>
          </div>
          <div className={css.bidStatsExplanation}>
            <FormattedMessage id="BidOffersPage.numberOfBids" />
          </div>
          <div className={css.bidStatsUnit}>
            &nbsp;
          </div>
        </div>
      </div>
    ) : null;

    const showOtherOffers =
      /*txIsRequested( transaction ) &&*/ bidOffers && this.state.showOtherOffers;

    const otherOffersLink = /*txIsRequested( transaction ) &&*/ bidOffers ? (
      <div
        className={css.otherOffersLink}
        onClick={() => this.setState({ showOtherOffers: !this.state.showOtherOffers })}
      >
        All offers
      </div>
    ) : null;

    const otherOffersBlock = showOtherOffers ? (
      <div className={css.otherOffersBlock}>
        {bidOffers.map( bidOffer => {
          const unitPurchase = bidOffer.attributes.lineItems.find(
            item => item.code === unitType && !item.reversal
          );

          const formattedUnitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;

          return (
            <div className={css.otherOfferRow}>
              <div className={css.otherOfferAvatar}>
                <AvatarSmall
                  className={css.otherOfferAvatarWrapper}
                  user={bidOffer.customerProfile}
                />
              </div>
              <div className={css.otherOfferName}>
                {bidOffer.customerProfile.attributes.profile.displayName}
              </div>
              <div className={css.otherOfferAmount}>
                {formattedUnitPrice}
              </div>
              { isProvider ? (
                <div className={css.otherOfferLink}>
                  { currentTransaction.id.uuid === bidOffer.id.uuid ? (
                    <FormattedMessage id='TransactionPanel.thisOfferLabel'/>
                  ) : (
                    <NamedLink name="SalePage" params={{ id: bidOffer.id.uuid }}>
                      <FormattedMessage id='TransactionPanel.otherOfferLabel'/>
                    </NamedLink>
                  )}
                </div>
              ) : null }
            </div>
          );
        })}
      </div>
    ) : null;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {bidStatsRow}
        {otherOffersLink}
        {otherOffersBlock}
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                listing={currentListing}
                transaction={currentTransaction}
                transactionRole={transactionRole}
              />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            { currentTransaction && txIsFunded( currentTransaction ) && isCustomer ? (
              <>
                <FormattedMessage
                  id="TransactionPanel.fullName"
                  values={{ fullName: currentProvider.attributes.profile.publicData.fullname }}
                />
              </>
            ) : (
              <FormattedMessage id="TransactionPanel.userNameHidden"/>
            )}
            {solanaPaymentClause}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />
            {showSendMessageForm ? (
              txIsFunded( currentTransaction ) ? (
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                />
              ) : (
                <div className={css.messagingIsLocked}>
                  <FormattedMessage id="TransactionPage.messagingIsLocked" />
                </div>
              )
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons || stateData.showCancelButton ? (
              currentUser.attributes.profile.publicData.solanaWalletAddress ? (
                <div className={css.mobileActionButtons}>{saleButtons}</div>
              ) : (
                <div className={css.acceptanceIsLocked}>
                  <FormattedMessage
                    id="TransactionPage.acceptanceIsLocked"
                    values={{
                      solanaWalletAddressForPayouts: (
                        <NamedLink name="SolanaPayoutPage">
                          <FormattedMessage id="TransactionPage.solanaWalletAddressForPayouts"/>
                        </NamedLink>
                      ),
                    }}
                  />
                </div>
              )
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              ) : null}
              <BreakdownMaybe
                className={css.breakdownContainer}
                listing={currentListing}
                transaction={currentTransaction}
                transactionRole={transactionRole}
              />

              {stateData.showSaleButtons || stateData.showCancelButton ? (
                currentUser.attributes.profile.publicData.solanaWalletAddress ? (
                  <div className={css.desktopActionButtons}>{saleButtons}</div>
                ) : (
                    <div className={css.acceptanceIsLocked}>
                      <FormattedMessage
                        id="TransactionPage.acceptanceIsLocked"
                        values={{
                          solanaWalletAddressForPayouts: (
                            <NamedLink name="SolanaPayoutPage">
                              <FormattedMessage id="TransactionPage.solanaWalletAddressForPayouts"/>
                            </NamedLink>
                          ),
                        }}
                      />
                    </div>
                  )
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        <Modal
          id="TransactionPanel.cancellationReason"
          contentClassName={css.cancellationReasonModalContent}
          isOpen={this.state.isCancellationReasonModalOpen}
          onClose={() => this.setState({ isCancellationReasonModalOpen: false })}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <CancellationReasonForm
            className={css.cancellationReasonForm}
            submitButtonWrapperClassName={css.cancellationReasonSubmitButtonWrapper}
            cancelSaleError={cancelSaleError}
            onSubmit={this.onSubmitSaleCancellation}
            inProgress={cancelSaleInProgress}
          />
        </Modal>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
