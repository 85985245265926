import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import city1NewYorkImage from './images/location_city1_new_york.jpg';
import city2MiamiImage from './images/location_city2_miami.jpg';
import city3ChicagoImage from './images/location_city3_chicago.jpg';
import city4LosAngelesImage from './images/location_city4_los_angeles.jpg';
import city5BostonImage from './images/location_city5_boston.jpg';
import city6HoustonImage from './images/location_city6_houston.jpg';
import city7PhiladelphiaImage from './images/location_city7_philadelphia.jpg';
import city8PhoenixImage from './images/location_city8_phoenix.jpg';
import city9AtlantaImage from './images/location_city9_atlanta.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.subtitle}>
        <FormattedMessage id="SectionLocations.subtitle" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'New York',
          city1NewYorkImage,
          '?address=New%20York%20City%2C%20New%20York%2C%20United%20States&bounds=40.917577%2C-73.700272%2C40.477399%2C-74.25909'
        )}
        {locationLink(
          'Miami',
          city2MiamiImage,
          '?address=Miami%2C%20Florida%2C%20United%20States&bounds=25.9794350332115%2C-80.1308910140325%2C25.5342490102697%2C-80.8736019999858'
        )}
        {locationLink(
          'Chicago',
          city3ChicagoImage,
          '?address=Chicago%2C%20Illinois%2C%20United%20States&bounds=42.023136999691%2C-87.5077920639626%2C41.6257400089756%2C-87.9310852234722'
        )}
      </div>
      <div className={css.locations}>
        {locationLink(
          'Los Angeles',
          city4LosAngelesImage,
          '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.1614409628202%2C-118.126644633049%2C33.9018943961945%2C-118.521476933898'
        )}
        {locationLink(
          'Boston',
          city5BostonImage,
          '?address=Boston%2C%20Massachusetts%2C%20United%20States&bounds=42.3969789945055%2C-70.9862240485867%2C42.2279251715472%2C-71.1911559808676'
        )}
        {locationLink(
          'Houston',
          city6HoustonImage,
          '?address=Houston%2C%20Texas%2C%20United%20States&bounds=30.0403639807333%2C-95.0612081155106%2C29.5289219526407%2C-95.7279796190761'
        )}
      </div>
      <div className={css.locations}>
        {locationLink(
          'Philadelphia',
          city7PhiladelphiaImage,
          '?address=Philadelphia%2C%20Pennsylvania%2C%20United%20States&bounds=40.1379938330353%2C-74.9557610264649%2C39.8559420290523%2C-75.2826797961931'
        )}
        {locationLink(
          'Phoenix',
          city8PhoenixImage,
          '?address=Phoenix%2C%20Arizona%2C%20United%20States&bounds=33.9183919999994%2C-111.925317113704%2C33.2902580947003%2C-112.342782'
        )}
        {locationLink(
          'Atlanta',
          city9AtlantaImage,
          '?address=Atlanta%2C%20Georgia%2C%20United%20States&bounds=34.072832%2C-84.2183130148288%2C33.5853520383246%2C-84.6739429965336'
        )}
      </div>

    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
