import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldSelect } from '../../components';

import css from './EditListingCancellationPolicyForm.module.css';

const EditListingCancellationPolicyFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        listing,
        disabled,
        ready,
        handleSubmit,
        intl,
        values,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const publicData = listing && listing.attributes && listing.attributes.publicData || {};
      const { timeUnit, checkInTime, checkOutTime } = publicData;

      const policyExplanations = {
        policy48Hours: timeUnit === 'night' ?
          {
            twoWeeksBeforeLabel: "14 Days Prior",
            twoWeeksBeforeTitle: "48 Hours After Booking",
            twoWeeksBeforeDescription: "For a full refund of accommodation fees, cancellation must be made within 48 hours of booking and at least 14 full days prior to listing’s local check-in time (or 3:00 PM if not specified) on the day of check-in.",
            moveInLabel: "Move In",
            moveInDate: "Fri, Jan 11",
            moveInTime: checkInTime ? checkInTime : "03:00 PM",
            moveInDescription: "For a full refund of accommodation fees, cancellation must be made 14 full days prior to listing’s local check in time (or 3:00 PM if not specified) on the day of check in, otherwise no refund. For example, if check-in is on Friday, cancel by Friday two weeks before check in time.",
            moveOutLabel: "Move Out",
            moveOutDate: "Mon, Jan 14",
            moveOutTime: checkOutTime ? checkOutTime : "11:00 AM",
            moveOutDescription: "If the guest cancels less than 14 days in advance, no refund is issued.",
          } : {
            moveInLabel: "Move In",
            moveInDate: "Fri, Jan 11",
            moveInTime: checkInTime ? checkInTime : "03:00 PM",
            moveInDescription: "If the guest books a long term reservation and decides to cancel the reservation later than 48 hours after the booking or less than 14 days before the local check-in time, the first month of the reservation is paid to the host in full and not refunded to the guest.",
            moveOutLabel: "Move Out",
            moveOutDate: "Mon, Jan 14",
            moveOutTime: checkOutTime ? checkOutTime : "11:00 AM",
            moveOutDescription: "If the guest books a reservation and decides to cancel the reservation during their stay, the guest must use the online alteration tool in order to agree to a new checkout date. Regardless of the checkout date chosen, the guest is required to pay the host for the 30 days following the cancellation date, or up to the end date of the guest’s original reservation if the remaining portion of the original reservation is less than 30 days.",
          }
      };

      const policyMessage = intl.formatMessage({ id: 'EditListingCancellationPolicyForm.title' });
      const policyPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCancellationPolicyForm.policyPlaceholder',
      });
      const policy48HoursOptionMessage = intl.formatMessage({
        id: timeUnit === 'night' ?
          'EditListingCancellationPolicyForm.policyNightly48HoursOption'
          : 'EditListingCancellationPolicyForm.policyMonthly48HoursOption'
      });
      const policyRequiredMessage = intl.formatMessage({
        id: 'EditListingCancellationPolicyForm.policyRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const nightlyExampleBlock = timeUnit === 'night' && values.cancellationPolicy ? (
        <>
          <div className={css.exampleBlockTitle}>Example</div>
          <div className={css.exampleBlock}>
            <div className={css.nightlyExampleBeforeTwoWeeksBlock}>
              <div className={css.exampleBeforeTwoWeeksLabel}>
                { policyExplanations[ values.cancellationPolicy ].twoWeeksBeforeLabel }
              </div>
              <div className={css.exampleBeforeTwoWeeksColorHeader}>&nbsp;</div>
              <div className={css.exampleMoveInDate}>
                { policyExplanations[ values.cancellationPolicy ].twoWeeksBeforeTitle }
              </div>
              <div className={css.exampleMoveInTime}>
                &nbsp;
              </div>
              <div className={css.exampleMoveInDescription}>
                { policyExplanations[ values.cancellationPolicy ].twoWeeksBeforeDescription }
              </div>
            </div>
            <div className={css.nightlyExampleMoveInBlock}>
              <div className={css.exampleMoveInLabel}>
                { policyExplanations[ values.cancellationPolicy ].moveInLabel }
              </div>
              <div className={css.exampleMoveInColorHeader}>&nbsp;</div>
              <div className={css.exampleMoveInDate}>
                { policyExplanations[ values.cancellationPolicy ].moveInDate }
              </div>
              <div className={css.exampleMoveInTime}>
                { policyExplanations[ values.cancellationPolicy ].moveInTime }
              </div>
              <div className={css.exampleMoveInDescription}>
                { policyExplanations[ values.cancellationPolicy ].moveInDescription }
              </div>
            </div>
            <div className={css.nightlyExampleMoveOutBlock}>
              <div className={css.exampleMoveOutLabel}>
                { policyExplanations[ values.cancellationPolicy ].moveOutLabel }
              </div>
              <div className={css.exampleMoveOutColorHeader}>&nbsp;</div>
              <div className={css.exampleMoveOutDate}>
                { policyExplanations[ values.cancellationPolicy ].moveOutDate }
              </div>
              <div className={css.exampleMoveOutTime}>
                { policyExplanations[ values.cancellationPolicy ].moveOutTime }
              </div>
              <div className={css.exampleMoveOutDescription}>
                { policyExplanations[ values.cancellationPolicy ].moveOutDescription }
              </div>
            </div>
          </div>
        </>
      ) : null;

      const monthlyExampleBlock = timeUnit === 'month' && values.cancellationPolicy ? (
        <>
          <div className={css.exampleBlockTitle}>Example</div>
          <div className={css.exampleBlock}>
            <div className={css.monthlyExampleMoveInBlock}>
              <div className={css.exampleMoveInLabel}>
                { policyExplanations[ values.cancellationPolicy ].moveInLabel }
              </div>
              <div className={css.exampleMoveInColorHeader}>&nbsp;</div>
              <div className={css.exampleMoveInDate}>
                { policyExplanations[ values.cancellationPolicy ].moveInDate }
              </div>
              <div className={css.exampleMoveInTime}>
                { policyExplanations[ values.cancellationPolicy ].moveInTime }
              </div>
              <div className={css.exampleMoveInDescription}>
                { policyExplanations[ values.cancellationPolicy ].moveInDescription }
              </div>
            </div>
            <div className={css.monthlyExampleMoveOutBlock}>
              <div className={css.exampleMoveOutLabel}>
                { policyExplanations[ values.cancellationPolicy ].moveOutLabel }
              </div>
              <div className={css.exampleMoveOutColorHeader}>&nbsp;</div>
              <div className={css.exampleMoveOutDate}>
                { policyExplanations[ values.cancellationPolicy ].moveOutDate }
              </div>
              <div className={css.exampleMoveOutTime}>
                { policyExplanations[ values.cancellationPolicy ].moveOutTime }
              </div>
              <div className={css.exampleMoveOutDescription}>
                { policyExplanations[ values.cancellationPolicy ].moveOutDescription }
              </div>
            </div>
          </div>
        </>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldSelect
            className={css.policyField}
            name="cancellationPolicy"
            id="cancellationPolicy"
            label={policyMessage}
            validate={required(policyRequiredMessage)}
          >
            <option value="" disabled={true}>
              {policyPlaceholderMessage}
            </option>
            <option value="policy48Hours">
              {policy48HoursOptionMessage}
            </option>
          </FieldSelect>

          {nightlyExampleBlock}
          {monthlyExampleBlock}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCancellationPolicyFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingCancellationPolicyFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingCancellationPolicyFormComponent);
