import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, IconEnquiry } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './CancellationReasonForm.css';

const CancellationReasonFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        values,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        cancelSaleError
      } = fieldRenderProps;

      const reasonLabel = intl.formatMessage(
        {
          id: 'CancellationReasonForm.reasonLabel',
        },
        { authorDisplayName }
      );
      const reasonRequiredMessage = intl.formatMessage({
        id: 'CancellationReasonForm.reasonRequired',
      });
      const reasonRequired = validators.required(reasonRequiredMessage);


      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconEnquiry className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="CancellationReasonForm.heading" values={{ listingTitle }} />
          </h2>
          <FieldSelect
            id={formId ? `${formId}.reason` : 'reason'}
            name="reason"
            className={css.field}
            label={reasonLabel}
            validate={reasonRequired}
          >
            <option disabled value="">
              Select reason for cancellation...
            </option>
            <option key="customer-credit" value="customer-credit">
              Customer Credit
            </option>
            <option key="no-proof-of-income" value="no-proof-of-income">
              No Proof Of Income
            </option>
            <option key="no-longer-available" value="no-longer-available">
              No Longer Available
            </option>
            <option key="will-handle-transaction-with-customer-provider-on-my-own" value="will-handle-transaction-with-customer-provider-on-my-own">
              Will Handle Transaction With Customer/Provider On My Own
            </option>
          </FieldSelect>
          <div className={submitButtonWrapperClassName}>
            {cancelSaleError ? (
              <p className={css.error}>
                <FormattedMessage id="CancellationReasonForm.cancelSaleError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="CancellationReasonForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

CancellationReasonFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

CancellationReasonFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CancellationReasonForm = compose(injectIntl)(CancellationReasonFormComponent);

CancellationReasonForm.displayName = 'CancellationReasonForm';

export default CancellationReasonForm;
