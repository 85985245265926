import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import GaugeChart from 'react-gauge-chart';
import NumericInput from 'react-numeric-input';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { nightsBetween } from '../../util/dates';
import { formatMoney } from '../../util/currency';
import { Form, Button, IconInfo, IconCalendar, FieldTextInput } from '../../components';

import css from './BidOffersForm.module.css';

const { Money } = sdkTypes;

const GUAGE_LEVELS = [ 0.17, 0.5, 0.83 ];
const DEMAND_LEVEL_TITLES = ['Low Demand', 'Medium Demand', 'High Demand'];
const DEMAND_LEVEL_SUBTITLES = ['0-3 Applicants', '4-6 Applicants', '6+ Applicants'];

class BidOffersFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const copyValue = ([fieldName, newValue], state, { changeValue }) => {
      changeValue(state, fieldName, () => newValue)
    };

    const ffProps = {...this.props};
    ffProps.mutators = { copyValue };

    return (
      <FinalForm
        {...ffProps}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onFetchBidOffers,
            onSendBidOffer,
            pristine,
            rootClassName,
            listing,
            bookingDates,
            bidOffers = [],
            maxBidOffer,
            fetchBidOffersInProgress,
            fetchBidOffersError,
            sendBidOfferInProgress,
            sendBidOfferError,
            form,
            values,
          } = fieldRenderProps;

          const publicData = listing && listing.attributes && listing.attributes.publicData || {};
          const { timeUnit = ''} = publicData;
          const timeUnitKey = timeUnit === 'month' ?
            "BidOffersForm.unitMonthlyHint" :
            "BidOffersForm.unitNightlyHint";

          const price = listing && listing.attributes && listing.attributes.price || { amount: 0 };
          const priceAmount = price.amount;
          const bidsAverageOffer = Array.isArray( bidOffers ) && bidOffers.length > 0 ?
            bidOffers.reduce(
              (previousValue, currentBid) => {
                return previousValue + currentBid.attributes.lineItems[0].unitPrice.amount;
              },
              0
            ) / bidOffers.length : 0;

          const averageOffer = new Money( bidsAverageOffer, 'USD');
          const formattedAverageOffer = formatMoney( intl, averageOffer );

          const numberOfDays =
            typeof bookingDates === 'object' && bookingDates.bookingStart && bookingDates.bookingEnd ?
              nightsBetween( bookingDates.bookingStart, bookingDates.bookingEnd ) : 0;

          // First name
          const maxBidOfferLabel = intl.formatMessage({
            id: 'BidOffersForm.maxBidOfferLabel',
          });
          const maxBidOfferPlaceholder = intl.formatMessage({
            id: 'BidOffersForm.maxBidOfferPlaceholder',
          });
          const maxBidOfferRequiredMessage = intl.formatMessage({
            id: 'BidOffersForm.maxBidOfferRequired',
          });
          const maxBidOfferRequired = validators.required(maxBidOfferRequiredMessage);

          const offerPriceHint = (
            <div className={css.suggestedPriceInputHint}>
              <IconInfo/>
              <FormattedMessage id={timeUnitKey}/>
            </div>
          );

          const submitError = sendBidOfferError ? (
            <div className={css.error}>
              <FormattedMessage id="BidOffersForm.sendBidOfferFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = sendBidOfferInProgress;
          const submitDisabled =
            invalid || pristine || sendBidOfferInProgress || submitInProgress;

          const guageLevel =
            bidOffers.length <= 3 ?
              GUAGE_LEVELS[ 0 ] : bidOffers.length <= 6 ? GUAGE_LEVELS[ 1 ] : GUAGE_LEVELS[ 2 ];
          const demandLevelTitle =
            bidOffers.length <= 3 ?
              DEMAND_LEVEL_TITLES[ 0 ] : bidOffers.length <= 6 ? DEMAND_LEVEL_TITLES[ 1 ] : DEMAND_LEVEL_TITLES[ 2 ];
          const demandLevelSubtitle =
            bidOffers.length <= 3 ?
              DEMAND_LEVEL_SUBTITLES[ 0 ] : bidOffers.length <= 6 ? DEMAND_LEVEL_SUBTITLES[ 1 ] : DEMAND_LEVEL_SUBTITLES[ 2 ];

          const offerPriceLevelLow =
            bidsAverageOffer < priceAmount * 0.65 ? formattedAverageOffer : ' ';
          const offerPriceLevelMedium =
            bidsAverageOffer >= priceAmount * 0.65 && bidsAverageOffer < priceAmount * 1.35 ?
              formattedAverageOffer : ' ';
          const offerPriceLevelHigh =
            bidsAverageOffer >= priceAmount * 1.35 ? formattedAverageOffer : ' ';

          const fetchError = fetchBidOffersError ? (
            <div className={css.error}>
              <FormattedMessage id="BidOffersForm.fetchBidOfferFailed" />
            </div>
          ) : null;

          return (
            <Form
              className={classes}
              onSubmit={e => { handleSubmit(e); }}
            >
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <div className={css.demandRow}>
                  <div className={css.guageCell}>
                    <GaugeChart
                      id="demandGuage"
                      nrOfLevels='3'
                      percent={guageLevel}
                      hideText={true}
                    />
                  </div>
                  <div className={css.demandCell}>
                    <div className={css.demandLevelTitle}>{demandLevelTitle}</div>
                    <div className={css.demandLevelSubtitle}>{demandLevelSubtitle}</div>
                  </div>
                </div>
                <div className={css.offerRow}>
                  <div className={css.offerPriceLevelCellWrapper}>
                    <div className={css.offerPriceLevelTitle}>
                      <FormattedMessage id="BidOffersForm.offerPriceLevelTitle"/>
                    </div>
                    <div className={css.offerPriceLevelCell}>
                      <div className={css.offerPriceLevelLow}>{offerPriceLevelLow}</div>
                      <div className={css.offerPriceLevelMedium}>{offerPriceLevelMedium}</div>
                      <div className={css.offerPriceLevelHigh}>{offerPriceLevelHigh}</div>
                    </div>
                  </div>
                  <div className={css.suggestedPriceOfferCell}>
                    <div className={css.offerPriceLevelTitle}>
                      {maxBidOfferLabel}
                    </div>
                    <div className={css.offerPriceInputRow}>
                      <div className={css.offerPriceInputPrefix}>$</div>
                      <NumericInput
                        className={css.suggestedPriceOfferInput}
                        style={{
                          input: { fontSize: '32px'},
                          btn: { borderColor: '#D55552', background: '#D55552'},
                          'btn:hover': { borderColor: '#B33330', background: '#B33330'},
                          plus: { backgroundColor: 'white'},
                          minus: { backgroundColor: 'white'}
                        }}
                        step={1}
                        precision={0}
                        mobile={true}
                        value={values.bidOffer}
                        min={1}
                        format={ num => num }
                        parse={ str => str.replace(/^\$/, "")}
                        onChange={ valueAsNumber => {
                          form.mutators.copyValue('bidOffer', valueAsNumber );
                        }}
                      />
                    </div>
                    <FieldTextInput
                      type="hidden"
                      id="bidOffer"
                      name="bidOffer"
                    />
                    {offerPriceHint}
                  </div>
                </div>
                <div className={css.bookingDaysRow}>
                  <IconCalendar/>
                  <div className={css.numberOfDays}>
                    <FormattedMessage id="BidOffersForm.numberOfDays"/>
                    {numberOfDays}
                  </div>
                </div>
              </div>
              {fetchError}
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={false}
              >
                <FormattedMessage id="BidOffersForm.sendOffer" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

BidOffersFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

BidOffersFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BidOffersForm = compose(injectIntl)(BidOffersFormComponent);

BidOffersForm.displayName = 'BidOffersForm';

export default BidOffersForm;
