import { storableError } from '../../util/errors';
import { fetchBidOffersLocal } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { speculateTransaction } from '../CheckoutPage/CheckoutPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_BID_OFFERS_REQUEST = 'app/BidConfirmationPage/FETCH_BID_OFFERS_REQUEST';
export const FETCH_BID_OFFERS_SUCCESS = 'app/BidConfirmationPage/FETCH_BID_OFFERS_SUCCESS';
export const FETCH_BID_OFFERS_ERROR = 'app/BidConfirmationPage/FETCH_BID_OFFERS_ERROR';

export const SET_BOOKING_DATES_REQUEST = 'app/BidConfirmationPage/SET_BOOKING_DATES_REQUEST';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  startDate: null,
  endDate: null,
  bidOffers: [],
  maxBidOffer: 0,
  fetchBidOffersInProgress: false,
  fetchBidOffersError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BID_OFFERS_REQUEST:
      return {
        ...state,
        listing: null,
        bidOffers: [],
        maxBidOffer: 0,
        fetchBidOffersInProgress: true,
        fetchBidOffersError: null,
      };
    case FETCH_BID_OFFERS_SUCCESS: {
      const { listing, bidOffers, maxBidOffer } = payload;

      return { ...state, listing, bidOffers, maxBidOffer, fetchBidOffersInProgress: false };
    }
    case FETCH_BID_OFFERS_ERROR: {
      return { ...state, fetchBidOffersInProgress: false, fetchBidOffersError: null };
    }

    case SET_BOOKING_DATES_REQUEST: {
      const { startDate, endDate } = payload;

      return { ...state, startDate, endDate };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const fetchBidOffersRequest = () => ({ type: FETCH_BID_OFFERS_REQUEST, payload: {}});
export const fetchBidOffersSuccess = results => ({ type: FETCH_BID_OFFERS_SUCCESS, payload: results });
export const fetchBidOffersError = error => ({
  type: FETCH_BID_OFFERS_ERROR,
  payload: error,
  error: true,
});

export const setBookingDatesRequest = ( startDate, endDate ) => ({
  type: SET_BOOKING_DATES_REQUEST,
  payload: { startDate, endDate }
});

// ================ Thunk ================ //

export const fetchBidOffers = ( listingId, startDate, endDate ) => {
  return (dispatch, getState, sdk) => {
    dispatch(fetchBidOffersRequest());

    const fetchOffers = typeof window === 'object' ?
      fetchBidOffersLocal({ listingId, startDate, endDate }) :
        Promise.resolve({ data: { listing: null, maxBidOffer: 0, transactions: []}});

    let listing, maxBidOffer, bidOffers;

    return fetchOffers
      .then( result => {
        maxBidOffer = result.data.maxBidOffer;
        bidOffers =  result.data.bidOffers;

        return sdk.listings.show({
          id: new UUID( listingId ),
          include: ['author', 'author.profileImage', 'images'],
          'fields.image': [
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',

            // Social media
            'variants.facebook',
            'variants.twitter',

            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',

            // Avatars
            'variants.square-small',
            'variants.square-small2x',
          ],
        });
      })
      .then(result => {
        const entities = denormalisedResponseEntities( result );
        listing = entities[ 0 ];

        dispatch(addMarketplaceEntities( result ));

        return dispatch(fetchBidOffersSuccess({ listing, maxBidOffer, bidOffers }));
      })
      .catch(e => {
        console.error('Error fetching the bid offers');
        console.error( e );
        dispatch(fetchBidOffersError(storableError(e)));
      });
  };
};

export const loadData = params => (dispatch, getState, sdk) => {
  const state = getState().SolanaCheckoutPage;

  const startDate =
    state.bookingDates && state.bookingDates.bookingStart ? state.bookingDates.bookingStart : null;
  const endDate =
    state.bookingDates && state.bookingDates.bookingEnd ? state.bookingDates.bookingEnd : null;
  const bidOffer = state.bidOffer ? state.bidOffer : null;
  const numberOfTenants = state.bookingData && state.bookingData.numberOfTenants ?
    state.bookingData.numberOfTenants : null;
  const numberOfChildren = state.bookingData && state.bookingData.numberOfChildren ?
    state.bookingData.numberOfChildren : null;
  const numberOfInfants = state.bookingData && state.bookingData.numberOfInfants ?
    state.bookingData.numberOfInfants : null;

  return Promise.all([
    dispatch( fetchBidOffers( params.id, startDate, endDate )),
    dispatch( speculateTransaction(
      {
        listingId: params.id,
        bookingStart: startDate,
        bookingEnd: endDate,
        bidOffer,
        numberOfTenants,
        numberOfChildren,
        numberOfInfants,
      },
      null
    ))
  ]);
};
