import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import LineItemFirstMonthPrice from './LineItemFirstMonthPrice';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

export const DAYS_PER_MONTH = 30;
export const PERIOD_STARTING_RANGE = 'starting-range';
export const PERIOD_FULL_MONTH = 'full-month';
export const PERIOD_ENDING_RANGE = 'ending-range';

const BookingPeriod = props => {
  const {
    startDate, endDate, periodType, nightlyAmount, monthlyAmount, nightlyPrice,
    monthlyPrice, securityDeposit, isProvider, isFirstMonth, firstMonthPrice,
    allowInstallments, intl
  } = props;

  const dateFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const propertyRentAmount = periodType === PERIOD_FULL_MONTH ?
    monthlyAmount : nightlyAmount * nightsBetween(startDate, endDate);
  const propertyRent = new Money( propertyRentAmount, 'USD');
  const propertyRentTotal = new Money( propertyRentAmount, 'USD');
  if( isFirstMonth && securityDeposit )
    propertyRentTotal.amount += securityDeposit.amount;

  const formattedPropertyRent =
    propertyRent ? formatMoney(intl, propertyRent) : null;
  const formattedPropertyRentTotal =
    propertyRentTotal ? formatMoney(intl, propertyRentTotal) : null;

  const listingPriceAmount = periodType === PERIOD_FULL_MONTH ?
    monthlyPrice : nightlyPrice * nightsBetween(startDate, endDate);
  const listingPriceValue = new Money( listingPriceAmount, 'USD');
  const formattedListingPriceValue =
    listingPriceValue ? formatMoney(intl, listingPriceValue) : null;

  const negotiation = propertyRentAmount && listingPriceAmount ?
    new Money( propertyRentAmount - listingPriceAmount, 'USD') : null;
  const formattedNegotiation =
    negotiation ? formatMoney(intl, negotiation) : null;
  const formattedSecurityDeposit =
    securityDeposit ? formatMoney(intl, securityDeposit) : null;

  return (
    <>
      { isFirstMonth ? (
        <LineItemFirstMonthPrice
          isProvider={isProvider}
          price={firstMonthPrice}
          intl={intl}
        />
      ) : null }

      <div className={css.lineItem}>
        <div className={css.subscriptionPeriod}>
          <div className={css.bookingPeriodSection}>
            <div className={css.itemLabel}>
              From&nbsp;&nbsp;&nbsp;
              <FormattedDate value={startDate} {...dateFormatOptions} />
            </div>
          </div>

          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.itemLabel}>
              Till&nbsp;&nbsp;&nbsp;
              <FormattedDate value={endDate} {...dateFormatOptions} />
            </div>
          </div>
        </div>
      </div>

      <div className={css.lineItem}>
        <div className={css.subscriptionRent}>
          <div className={css.bookingPeriodSection}>
            <div className={css.itemLabel}>
              <FormattedMessage id='BookingBreakdown.basePriceLabel' />
            </div>
          </div>

          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.itemLabel}>
              {formattedPropertyRent}
            </div>
          </div>
        </div>
      </div>

      <div className={css.lineItem}>
        <div className={css.subscriptionListingPriceValue}>
          <div className={css.bookingPeriodSection}>
            <div className={css.itemLabel}>
              <FormattedMessage id='BookingBreakdown.listingPriceValueLabel' />
            </div>
          </div>

          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.itemLabel}>
              {formattedListingPriceValue}
            </div>
          </div>
        </div>
      </div>

      <div className={css.lineItem}>
        <div className={css.subscriptionNegotiation}>
          <div className={css.bookingPeriodSection}>
            <div className={css.itemLabel}>
              <FormattedMessage id='BookingBreakdown.negotiation' />
            </div>
          </div>

          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.itemLabel}>
              {formattedNegotiation}
            </div>
          </div>
        </div>
      </div>

      { isFirstMonth && securityDeposit ? (
        <div className={css.lineItem}>
          <div className={css.subscriptionNegotiation}>
            <div className={css.bookingPeriodSection}>
              <div className={css.itemLabel}>
                <FormattedMessage id='BookingBreakdown.securityDeposit' />
              </div>
            </div>

            <div className={css.bookingPeriodSectionRigth}>
              <div className={css.itemLabel}>
                {formattedSecurityDeposit}
              </div>
            </div>
          </div>
        </div>
      ) : null }

      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <div className={css.subscriptionRent}>
          <div className={css.bookingPeriodSection}>
            <div className={css.itemLabel}>
              <FormattedMessage id='BookingBreakdown.rangeTotal' />
            </div>
          </div>

          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.itemLabel}>
              {formattedPropertyRentTotal}
            </div>
          </div>
        </div>
      </div>

      {
        allowInstallments ? (
          <>
            <hr className={css.allowInstallmentsDivider} />
            <div className={css.lineItemTotal}>
              <div className={css.allowInstallmentsLabel}>
                <FormattedMessage
                  id={ allowInstallments === 'yes'
                    ? "BookingBreakdown.allowInstallmentsLabel"
                    : "BookingBreakdown.doNotAllowInstallmentsLabel"
                  }
                />
              </div>
            </div>
          </>
        ) : null
      }
    </>
  );
};

const nightsBetween = (startDate, endDate) => {
  const nights = moment(endDate).diff(startDate, 'days');
  if (nights < 0) {
    throw new Error('End date cannot be before start date');
  }
  return nights;
};

const daysBetween = (startDate, endDate) => {
  const days = moment(endDate).diff(startDate, 'days');
  if (days < 0) {
    throw new Error('End date cannot be before start date');
  }
  return days;
};

const calculateQuantityFromDates = (startDate, endDate, type) => {
  if (type === LINE_ITEM_NIGHT) {
    return nightsBetween(startDate, endDate);
  } else if (type === LINE_ITEM_DAY) {
    return daysBetween(startDate, endDate);
  }
  throw new Error(`Can't calculate quantity from dates to unit type: ${type}`);
};

export const constructPeriods = ( startDate, endDate, dateType ) => {
  const quantity = calculateQuantityFromDates( startDate, endDate, dateType );
  const periods = [];

  const firstStartDate = new Date( startDate.getTime());
//  firstStartDate.setMonth( firstStartDate.getMonth() + 1 );
//  firstStartDate.setDate( 1 );
  if( firstStartDate.getTime() < endDate.getTime()){
    let periodStartDate = new Date( firstStartDate.getTime());
    let periodEndDate = new Date( firstStartDate.getTime());
    periodEndDate.setMonth( periodEndDate.getMonth() + 1 );
    periodEndDate.setDate( 1 );

    while( periodEndDate.getTime() <= endDate.getTime()){
      periodEndDate.setDate( 0 );
      periods.push({
        startDate: new Date( periodStartDate.getTime()),
        endDate: new Date( periodEndDate.getTime()),
        type: periodStartDate.getDate() === 1 ? PERIOD_FULL_MONTH : PERIOD_STARTING_RANGE
      });

      periodStartDate.setMonth( periodStartDate.getMonth() + 1 );
      periodStartDate.setDate( 1 );
      periodEndDate.setDate( periodEndDate.getDate() + 1 );
      periodEndDate.setMonth( periodEndDate.getMonth() + 1 );
    }

    if( periodStartDate.getTime() < endDate.getTime()){
      periods.push({
        startDate: periodStartDate,
        endDate: endDate,
        type: PERIOD_ENDING_RANGE
      });
    }
  }

  return periods;
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, transaction, listing, isProvider, intl } = props;
  const publicData = listing && listing.attributes && listing.attributes.publicData || {};
  const timeUnit = publicData.timeUnit;
  const { securityDeposit = 0 } = publicData;
  let monthlyPrice = listing && listing.attributes ? listing.attributes.price.amount : 0;
  let nightlyPrice = monthlyPrice;

  if( timeUnit === 'night'){
    monthlyPrice = nightlyPrice * DAYS_PER_MONTH;
  } else {
    nightlyPrice = Math.round( monthlyPrice / DAYS_PER_MONTH );
  }

  const protectedData =
    transaction && transaction.attributes && transaction.attributes.protectedData || {};
  const bidOffer = protectedData.bidOffer;
  let monthlyAmount = bidOffer ? Math.round( Number.parseFloat( bidOffer ) * 100 ) : 0;
  let nightlyAmount = monthlyAmount;

  if( timeUnit === 'night'){
    monthlyAmount = nightlyAmount * DAYS_PER_MONTH;
  } else {
    nightlyAmount = Math.round( monthlyAmount / DAYS_PER_MONTH );
  }

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = dateFromAPIToLocalNoon(displayStart || start);
  const localEndDateRaw = dateFromAPIToLocalNoon(displayEnd || end);

  const periods = constructPeriods( localStartDate, localEndDateRaw, unitType );

  return periods.length > 0 ? (
    <>
      { periods.map(( period, index ) => {
        return (
          <BookingPeriod
            startDate={period.startDate}
            endDate={period.endDate}
            periodType={period.type}
            nightlyAmount={nightlyAmount}
            monthlyAmount={monthlyAmount}
            nightlyPrice={nightlyPrice}
            monthlyPrice={monthlyPrice}
            isProvider={isProvider}
            isFirstMonth={ index === 0 }
            allowInstallments={ periods.length >= 2 && index === 0 ? publicData.allowInstallments : null }
            securityDeposit={ new Money( securityDeposit, 'USD')}
            firstMonthPrice={ isProvider ? transaction.attributes.payoutTotal : transaction.attributes.payinTotal }
            intl={intl}
          />
        )
      })}
    </>
  ) : null ;
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
