import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldRadioButton, FieldSelect } from '../../components';

import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import css from './EditListingAvailabilityForm.module.css';

export class EditListingAvailabilityFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
          } = formRenderProps;

          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          const allowInstantBookingsYesOption = intl.formatMessage({
            id: 'EditListingAvailabilityForm.allowInstantBookingsYes',
          });
          const allowInstantBookingsNoOption = intl.formatMessage({
            id: 'EditListingAvailabilityForm.allowInstantBookingsNo',
          });

          const tenantSpaceTypeEntirePlaceOption = intl.formatMessage({
            id: 'EditListingAvailabilityForm.tenantSpaceTypeEntirePlace',
          });
          const tenantSpaceTypePrivateRoomOption = intl.formatMessage({
            id: 'EditListingAvailabilityForm.tenantSpaceTypePrivateRoom',
          });
          const tenantSpaceTypeSharedRoomOption = intl.formatMessage({
            id: 'EditListingAvailabilityForm.tenantSpaceTypeSharedRoom',
          });

          const instantBookingAllowed = null; /*(
            <>
              <div className={css.allowInstantBookingsSubtitle}>
                <FormattedMessage id="EditListingAvailabilityForm.allowInstantBookingsSubtitle" />
              </div>
              <div className={css.allowInstantBookingsBlock}>
                <FieldRadioButton
                  id={'instantBookingAllowed-yes'}
                  name="instantBookingAllowed"
                  className={css.allowInstantBookingsRadiobutton}
                  label={allowInstantBookingsYesOption}
                  value="yes"
                  showAsRequired={true}
                />
                <FieldRadioButton
                  id={'instantBookingAllowed-no'}
                  name="instantBookingAllowed"
                  className={css.allowInstantBookingsRadiobutton}
                  label={allowInstantBookingsNoOption}
                  value="no"
                  showAsRequired={true}
                />
              </div>
            </>
          );*/

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              {instantBookingAllowed}

              <div className={css.tenantSpaceTypeSubtitle}>
                <FormattedMessage id="EditListingAvailabilityForm.tenantSpaceTypeSubtitle" />
              </div>
              <div className={css.tenantSpaceTypeBlock}>
                <FieldRadioButton
                  id={'tenantSpaceType-entirePlace'}
                  name="tenantSpaceType"
                  className={css.tenantSpaceTypeRadiobutton}
                  label={tenantSpaceTypeEntirePlaceOption}
                  value="entire-place"
                  showAsRequired={true}
                />
                <FieldRadioButton
                  id={'tenantSpaceType-privateRoom'}
                  name="tenantSpaceType"
                  className={css.tenantSpaceTypeRadiobutton}
                  label={tenantSpaceTypePrivateRoomOption}
                  value="private-room"
                  showAsRequired={true}
                />
                <FieldRadioButton
                  id={'tenantSpaceType-sharedRoom'}
                  name="tenantSpaceType"
                  className={css.tenantSpaceTypeRadiobutton}
                  label={tenantSpaceTypeSharedRoomOption}
                  value="shared-room"
                  showAsRequired={true}
                />
              </div>

              <div className={css.minBookingPeriodSubtitle}>
                <FormattedMessage id="EditListingAvailabilityForm.minBookingPeriodSubtitle" />
              </div>
              <FieldSelect
                id="minBookingPeriod"
                name="minBookingPeriod"
              >
                <option value="">Pick minimum period...</option>
                <option value="2">2 Days</option>
                <option value="7">7 Days</option>
                <option value="30">30 Days</option>
                <option value="180">180 Days (6 months)</option>
                <option value="365">365 Days (1yr)</option>
              </FieldSelect>

              <div className={css.calendarWrapper}>
                <ManageAvailabilityCalendar
                  availability={availability}
                  availabilityPlan={availabilityPlan}
                  listingId={listingId}
                />
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
