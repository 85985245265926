import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemUnitPrice = props => {
  const { price, isProvider, intl } = props;

  const formattedTotalPrice = formatMoney(intl, price);

  return (
    <>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          <FormattedMessage
            id={ isProvider ? "BookingBreakdown.firstMonthProviderPrice" : "BookingBreakdown.firstMonthCustomerPrice"}
            values={{ price: formattedTotalPrice }} />
        </div>
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
