import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_UNITS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemPersons = props => {
  const { transaction } = props;
  const { numberOfTenants, numberOfChildren, numberOfInfants } =
    transaction && transaction.attributes && transaction.attributes.protectedData || {};

  return (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.guestsLabel" />
        </span>
        <span className={css.itemValue}>
          <FormattedMessage
            id="BookingBreakdown.guestsText"
            values={{
              tenants: <b>{numberOfTenants}</b>,
              children: <b>{numberOfChildren}</b>,
              infants: <b>{numberOfInfants}</b>,
            }}
          />
        </span>
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};

LineItemPersons.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export default LineItemPersons;
