import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';
import { LandingPageComponent } from '../../containers/LandingPage/LandingPage';
import { connect } from 'react-redux';

const SectionHeroComponent = props => {
  const [ rentalType, setRentalType ] = useState('month');
  const { width } = props.viewport;

  const { rootClassName, className } = props;

  const heroClass = (rentalType === 'month') ? css.rootDaily : css.rootNightly;
  const classes = classNames(rootClassName || heroClass, className);
  const rentalMonthlyClass = (rentalType === 'month') ?
    css.monthlySelected : css.monthlyUnselected;
  const rentalNightlyClass = (rentalType === 'night') ?
    css.nightlySelected : css.nightlyUnselected;

  const rentalTypeSelector = (
    <div className={css.rentalTypeBlock}>
      <div className={css.heroMainSpace}/>
      <div className={rentalMonthlyClass} onClick={() => setRentalType('month')}>
        <FormattedMessage
          id={
            width > 700 ? "SectionHero.monthlyButton" : "SectionHero.monthlyMobileButton"
          }
        />
      </div>
      <div className={rentalNightlyClass} onClick={() => setRentalType('night')}>
        <FormattedMessage
          id={
            width > 700 ? "SectionHero.nightlyButton" : "SectionHero.nightlyMobileButton"
          }
        />
      </div>
      <div className={css.heroMainSpace}/>
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroMainTitleText}>
          <div className={css.heroMainSpace}/>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
            <div className={css.tmMainTitle}>
              <sup>
                TM
              </sup>
            </div>
          </h1>
          <div className={css.heroMainSpace}/>
        </div>
        {rentalTypeSelector}
        <div className={css.heroSubTitleText}>
          <div className={css.heroMainSpace}/>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <div className={css.heroMainSpace}/>
        </div>
        <div className={css.heroButtonWrapper}>
          <div className={css.heroButtonSpace}/>
          <NamedLink
            name="SearchPage"
            to={{
              search:
                'pub_timeUnit=' + rentalType,
            }}
            className={css.heroButton}
          >
            { rentalType === 'month' ? (
              <FormattedMessage id={
                width > 700
                  ? "SectionHero.browseMonthlyButton"
                  : "SectionHero.browseMonthlyMobileButton"
              } />
            ) : (
              <FormattedMessage id={
                width > 700
                  ? "SectionHero.browseNightlyButton"
                  : "SectionHero.browseNightlyMobileButton"
              } />
            )}
          </NamedLink>
          <div className={css.heroButtonSpace}/>
        </div>
      </div>
    </div>
  );
};

SectionHeroComponent.defaultProps = { rootClassName: null, className: null };

SectionHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const SectionHero = compose(
  withViewport,
)(SectionHeroComponent);

export default SectionHero;
